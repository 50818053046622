import _, { size } from "lodash";
import { useContext } from "react";
import Plus from "../../../assets/media/icons/other_icons/plus.svg";
import cross from "../../../assets/media/icons/standard_icons/cross.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { showAlert } from "../../../helpers/ShowAlert";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import { TxAccountContext } from "../core/TxAccountProvider";
import { getTXAccountOrdersListings } from "../core/_request";
import PDFView from "./PDFView";

const ExcludeContainerPayout = ({
  excludeOrderValue,
  setExcludeOrderValue,
  setDataList,
  setPayoutId,
  isDisabled,
  refetch,
}: any) => {
  const layout = useContext(TxAccountContext);
  const coreLayout = useContext(LayoutContext);

  // ON ENTER -  EXCLUDE ORDER
  const handleExcludeOrder = () => {
    let enteredIds: any = excludeOrderValue?.excludeOrder
      .split(/\s*,\s*|\s+/)
      .map((id: any) => id.trim());
    enteredIds = _.uniq(enteredIds);

    let existingIds: any =
      [
        ...(layout.orderPayoutSeller?.excludesOrderId || []),
        ...excludeOrderValue?.excludeOrderFilterChip,
      ];

    // Filter out IDs that are already in existingIds
    let uniqueIds = enteredIds.filter((id: any) => !existingIds.includes(id));

    if (uniqueIds?.length === 0) {
      showAlert("Please enter unique order ID", true);
    }

    uniqueIds.forEach((id: any) => {
      if (id.toString().length >= 4 && id.toString().length <= 36) {
        setExcludeOrderValue((current: any) => {
          let excludeOrderFilterChip = [...current.excludeOrderFilterChip, id];
          return {
            ...current,
            excludeOrder: "",
            excludeOrderFilterChip: excludeOrderFilterChip, // Use current.excludeOrderFilterChip to get the latest state
          };
        });
      }
    });
  };

  // CONFIRMED EXCLUDE
  const confirmedExclude = (excludeOrdersList: any, existingOrders: any) => {
    layout?.setCustomLoader(true);
    // setDataList((draft: any) => {
    // let listings = draft?.filter(
    //   (item: any) =>
    //     !excludeOrderValue?.excludeOrderFilterChip?.includes(item?.order_id)
    // );

    // MAKE ORDER ID'S EXCLUDE - API
    getTXAccountOrdersListings({
      orderFilters: layout?.orderFilters,
      get_teams: true,
      exclude_order_ids: [
        ...excludeOrdersList,
        ...(existingOrders ? existingOrders : []),
      ],
    }).then((res: any) => {
      layout?.setCustomLoader(false);

      let payoutResponse = res?.data?.team_payouts;
      let teamsOptions = coreLayout?.allowedAccess?.teams;
      let currency_payout_totals = res?.data?.currency_payout_totals;
      // IF SUCCESS
      if (res?.data?.team_payouts && size(res?.data?.team_payouts) > 0) {
        payoutResponse = payoutResponse?.map((item: any) => {
          return {
            ...item,
            teamName: teamsOptions.find(
              (option: any) => option.id === item.team_id
            ),
          };
        });

        layout?.setOrderPayoutSeller((current: any) => {
          return {
            ...current,
            excludesOrderId:
              current?.excludesOrderId && size(current?.excludesOrderId) > 0
                ? [
                    ...current?.excludesOrderId,
                    ...excludeOrderValue?.excludeOrderFilterChip,
                  ]
                : excludeOrderValue?.excludeOrderFilterChip,
            listing_data: {
              ...current?.listing_data,
              filterData: {
                ...current?.listing_data?.filterData,
                orders: {
                  value: res?.data?.orders_count_total,
                  excludes: res?.data?.orders_count_excluded,
                },
                payoutResponse,
                currency_payout_totals,
              },
            },
          };
        });
        setTimeout(() => {
          refetch();
        }, 500);
      }
    });

    setExcludeOrderValue((current: any) => {
      return {
        excludeOrder: "",
        excludeOrderFilterChip: [],
      };
    });

    // return listings;
    // });
  };

  return (
    <>
      {!layout?.orderPayoutSeller?.finish && (
        <div className="exclude_order p-5 pt-3.5 bg-gray-100/50 mt-[1.875rem] rounded">
          <h5 className="font-semibold text-sm14 leading-4 mb-[1.125rem]">
            Exclude orders
          </h5>
          <div className="exclude_input relative">
            <form className="flex flex-wrap flex-col">
              <input
                type="text"
                className={`w-full border rounded  border-gray-200 text-sm14 leading-5 px-2.5 py-1 placeholder:text-gray-400 ${
                  isDisabled ? "bg-gray-300/50" : "bg-white"
                }`}
                placeholder="Enter order ID or marketplace ID"
                name="excludeOrder"
                disabled={isDisabled}
                value={excludeOrderValue?.excludeOrder}
                onChange={(data: any) =>
                  setExcludeOrderValue((current: any) => {
                    return {
                      ...current,
                      excludeOrder: data.target.value,
                    };
                  })
                }
                onKeyDown={(data: any) => {
                  if (data?.key === "Enter") {
                    data?.preventDefault();
                    handleExcludeOrder();
                  }
                }}
              />
              <button
                className={`submit_exclude absolute top-0 right-0 w-5 h-full ${
                  isDisabled ? "pointer-events-none" : ""
                }`}
                type="button"
                disabled={isDisabled}
                data-tooltip-id={`add-exclude`}
                onClick={(data: any) => {
                  data?.preventDefault();
                  handleExcludeOrder();
                }}
              >
                <TooltipPortal
                  id={`add-exclude`}
                  content={"Add"}
                  className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
                />
                <IKTSVG
                  path={Plus}
                  className="fill-gray-400 hover:fill-indigo-500"
                  svgClassName="w-3 h-3"
                />
              </button>
            </form>
          </div>

          {/* EXCLUDE CHIPS */}
          {size(excludeOrderValue?.excludeOrderFilterChip) > 0 && (
            <div className="exclude_list flex flex-wrap gap-2.5 mt-5">
              {excludeOrderValue?.excludeOrderFilterChip?.map(
                (item: any, index: number) => {
                  return (
                    <div className="anim-fade single_list flex items-center text-sm12 rounded-xl px-2.5 py-[.1875rem] border bg-white">
                      <span>{item}</span>

                      <button
                        className="inline-flex items-center"
                        type="button"
                        data-tooltip-id={`item-${index}`}
                        onClick={(data: any) => {
                          setExcludeOrderValue((current: any) => {
                            return {
                              ...current,
                              excludeOrderFilterChip:
                                current?.excludeOrderFilterChip?.filter(
                                  (excludeItem: any) => excludeItem !== item
                                ),
                            };
                          });
                        }}
                      >
                        <TooltipPortal
                          id={`item-${index}`}
                          content={"Clear"}
                          className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
                        />
                        <IKTSVG
                          path={cross}
                          className="fill-violet-500 hover:fill-indigo-500 ml-2 cursor-pointer"
                          svgClassName="w-2 h-2"
                        />
                      </button>
                    </div>
                  );
                }
              )}
            </div>
          )}
        </div>
      )}

      {/* FILTER CHIP */}
      {size(excludeOrderValue?.excludeOrderFilterChip) > 0 && (
        <div className="anim-fade confirm_exclude px-5 py-3.5 flex items-center flex-wrap gap-2.5 text-sm13 bg-gray-100/50 border-t rounded-b">
          <span>
            Exclude{" "}
            {size(excludeOrderValue?.excludeOrderFilterChip) === 1
              ? `${size(excludeOrderValue?.excludeOrderFilterChip)} order?`
              : `${size(
                  excludeOrderValue?.excludeOrderFilterChip
                )} orders?`}{" "}
          </span>
          <div className="buttons gap-2.5   flex flex-wrap">
            <button
              className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
         bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-[.0625rem] leading-5"
              type="button"
              onClick={(data: any) =>
                setExcludeOrderValue((current: any) => {
                  return {
                    ...current,
                    excludeOrderFilterChip: [],
                  };
                })
              }
            >
              Cancel
            </button>
            <button
              type="button"
              className="group inline-flex group items-center   transition font-medium rounded px-2.5 py-0.5 leading-5
                      bg-violet-500 hover:bg-indigo-500 text-white hover:text-white
                      "
              onClick={(data: any) =>
                confirmedExclude(
                  excludeOrderValue?.excludeOrderFilterChip,
                  layout.orderPayoutSeller?.excludesOrderId
                )
              }
            >
              Confirm
            </button>
          </div>
        </div>
      )}

      {/** CONFIRMED EXCLUDED ORDERS */}
      {_.size(layout?.orderPayoutSeller?.excludesOrderId) > 0 && (
        <div className="exclude_order p-5 pt-3.5 bg-gray-100/50 mt-[1.875rem] rounded">
          <h5 className="font-semibold text-sm14 leading-4 mb-[1.125rem]">
            Excluded orders
          </h5>
          <div className="exclude_input relative"></div>
          <div className="exclude_list flex flex-wrap gap-2.5 mt-5">
            {layout?.orderPayoutSeller?.excludesOrderId?.map(
              (id: any, key: number) => {
                return (
                  <div
                    className="anim-fade single_list flex items-center text-sm12 rounded-xl px-2.5 py-[.1875rem] border bg-white"
                    key={`confired-excluded-order-${key}`}
                  >
                    <span>{id}</span>
                  </div>
                );
              }
            )}
          </div>
        </div>
      )}

      {/* ORDER VIEW IN ACCORDION */}
      {layout?.orderPayoutSeller?.finish &&
        layout.orderPayoutSeller?.team_payout_ids &&
        layout.orderPayoutSeller?.team_payout_ids?.map((item: any) => {
          return (
            <PDFView
              fileName={item?.teamName?.name}
              title="View pay out statement"
              isLoading={false}
              draftKey={item?.payout_id}
              downloadPDF={(data: any, type: string, name: string) => {
                setPayoutId(data);
              }}
            />
          );
        })}
    </>
  );
};

export default ExcludeContainerPayout;
