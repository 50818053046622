import { IKTSVG } from "../../../../components/ui/IKTSVG";

const EventHeader = ({ className, title, Imgpath, svgClassName }: any) => {
  return (
    <>
      <div className={className}>
        <IKTSVG
          className="flex items-center justify-center min-w-[0.75] fill-white mr-2"
          path={Imgpath}
          svgClassName= {svgClassName}
        />
        <p>{title}</p>
      </div>
    </>
  );
};

export default EventHeader;
