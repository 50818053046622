import _, { size } from "lodash";
import moment from "moment";
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import average from "../../../../assets/media/icons/other_icons/average.svg";
import close from "../../../../assets/media/icons/other_icons/Clear.svg";
import filter from "../../../../assets/media/icons/other_icons/filter.svg";
import high from "../../../../assets/media/icons/other_icons/high.svg";
import level1 from "../../../../assets/media/icons/other_icons/level1.svg";
import level2 from "../../../../assets/media/icons/other_icons/level2.svg";
import level3 from "../../../../assets/media/icons/other_icons/level3.svg";
import level4 from "../../../../assets/media/icons/other_icons/level4.svg";
import low from "../../../../assets/media/icons/other_icons/low.svg";
import lowwithcolor from "../../../../assets/media/icons/other_icons/lowwithcolor.svg";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import DatePickerField from "../../../../components/formComponent/DatePickerField";
import MultiDropDownField from "../../../../components/formComponent/MultiDropDownField";
import MultiSelect from "../../../../components/formComponent/MultiSelect";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { KTSVG } from "../../../../components/ui/KTSVG";
import {
  addCommaInNumber,
  addSelectAll,
  getProperTicketTypeWithSubticketCombine,
  handleSelectDeselectAll,
  removeSelectAll,
  ticketTypeRename,
} from "../../../../helpers/Functions";
import HeaderfilterContainer from "../../components/HeaderfilterContainer";
import InventoryHeader from "../../components/InventoryHeader";
import { getInventoryOption } from "../../core/_request";
import BulkOperations from "./BulkOperations";
import InventoryPagination from "./InventoryPagination";

const InventoryFilter = memo(
  ({
    list,
    isTableDisabled,
    bulkActionRef,
    bulkAction1Ref,
    bulkAction3Ref,
    isAddInventory,
    layout,
    listCountingInfo,
    publishStatus,
    setPublishStatus,
    algoliaData,
    algoliaOptions,
    algoliaLoading,
    setPagination,
    confirmedBulkAction,
    setTeamsSelected,
    setInventoryFilterHandle,
    setCustomLoading,
    dateRange,
    inventoryFilterHandle,
    users,
    startListing,
    paginateData,
    setQuery,
    setEventId,
    setInputChange,
    setDateRange,
    userSpecific,
    teamsSelected,
    query,
    debouncedSearch,
    PUBLISH_STATUS,
    getFilterIndex,
    getFilterIndexForCheckbox,
    onSearch,
    handleInputChange,
    inputChange,
    handleKeyDown,
    isGodAdmin,
    teamsOptions,
    isLoading,
    setList,
    uniqeKey,
    loadMoreListing,
  }: any) => {
    const [shimmerLoader, setShimmerLoader] = useState<any>(true);
    const coreLayout = useContext(LayoutContext);

    // console.log(users);

    useEffect(() => {
      setShimmerLoader(isLoading);
    }, [isLoading]);

    /*for sticky event bar*/
    const heightRef1: any = useRef();
    const heightRef2: any = useRef();
    const toggleRef: any = useRef();
    const filterRef: any = useRef();
    const listingRef: any = useRef();
    const ticketTypeRef: any = useRef();
    const categoryRef: any = useRef();
    const ticketStatusRef: any = useRef();

    const [IsToggle, setIsToggle] = useState<any>(true);

    const toggle = document.querySelector(
      '[data-te-target="#inventoryaccordion"]'
    );

    const [overViewHeight, setOverViewHeight] = useState<any>();
    useEffect(() => {
      let height3: any;
      const element: any = document.querySelector("#inventoryaccordion");
      setTimeout(() => {
        height3 = IsToggle === true ? element?.offsetHeight : 0;
        setOverViewHeight(height3);
      }, 400);
    }, [IsToggle]);

    // useEffect(() => {
    //   // const element = document.getElementById(
    //   //   "inventoryaccordion"
    //   // ) as HTMLElement;

    //   // // const MarginTopTotle =
    //   // //   heightheightRef1 + heightheightRef2 + heightAsNumber - 60;
    //   // console.log(
    //   //   heightheightRef1,
    //   //   heightheightRef2,
    //   //   heightAsNumber,
    //   //   height3,
    //   //   "abc"
    //   // );
    //   const stickyElement = document.getElementById(
    //     "topFilters"
    //   ) as HTMLElement;
    //   const stickyElementStyle = window.getComputedStyle(stickyElement);
    //   const stickyElementheight: any =
    //     stickyElementStyle.getPropertyValue("height");

    //   if (stickyElement) {
    //     const heightheightRef1 = heightRef1?.current?.offsetHeight;
    //     const heightheightRef2 = heightRef2?.current?.offsetHeight;

    //     const MarginTopTotle =
    //       IsToggle === true
    //         ? heightheightRef1 + overViewHeight - 59
    //         : heightheightRef1 - 59;

    //     const HeaderHeight: any =
    //       document.querySelector("header")?.clientHeight;
    //     const initialPosition = stickyElement.getBoundingClientRect().top;
    //     const accordion = document.getElementById("inventoryaccordion");
    //     const accorAttr = accordion?.getAttribute("data-te-collapse-show");

    //     const makeSticky = function () {
    //       if (window.scrollY + 60 >= initialPosition) {
    //         stickyElement.classList.add("sticky");
    //         stickyElement.style.top = `-${MarginTopTotle}px`;
    //       } else {
    //         stickyElement.classList.remove("sticky");
    //         stickyElement.style.top = `0px`;
    //       }
    //     };

    //     if (window.scrollY > 200) {
    //       makeSticky();
    //     }

    //     window.addEventListener("scroll", makeSticky);
    //     window.addEventListener("change", makeSticky);
    //     window.addEventListener("blur", makeSticky);
    //     return () => {
    //       window.removeEventListener("scroll", makeSticky);
    //     };
    //   }
    //   console.log(IsToggle, "IsToggle");
    //   // });
    // }, [toggleRef, IsToggle, overViewHeight]);
    /* end sticky event */

    function calculateBodyTotalHeight() {
      const bodyComputedStyle = window.getComputedStyle(document.body);
      const bodyHeight =
        document.body.offsetHeight +
        parseInt(bodyComputedStyle.marginTop) +
        parseInt(bodyComputedStyle.marginBottom);

      return bodyHeight;
    }

    const [height, setHeight] = useState<any>();
    useEffect(() => {
      const stickyElement = document.getElementById(
        "topFilters"
      ) as HTMLElement;
      const stickyElementStyle = window.getComputedStyle(stickyElement);
      const stickyElementheight: any =
        stickyElementStyle.getPropertyValue("height");

      const filter = document.getElementById("filters_block") as HTMLElement;
      // const heightheightRef2 = filter.offsetHeight;

      // let openEvent = list?.filter(
      //   (obj: any) => obj?.isOpen || obj?.isOpenMarket
      // );
      // let changedDataCount: any = openEvent?.reduce(
      //   (count: any, event: any) => {
      //     // const isOpen = event?.isOpen;
      //     const changedRecords = Number(event?.total_listings);

      //     return count + (changedRecords ? changedRecords : 0);
      //   },
      //   0
      // );
      if (stickyElement) {
        // const heightheightRef2 = heightRef2?.current?.offsetHeight;

        const initialPosition = stickyElement.getBoundingClientRect().top;
        const accordion = document.getElementById("inventoryaccordion");

        const accorAttr = accordion?.getAttribute("data-te-collapse-show");

        let MarginTopTotle = 0;
        let evtHeadTop;
        // const MarginTopTotle =
        //     IsToggle === true
        //       ? heightheightRef1 + overViewHeight - HeaderHeight
        //       : heightheightRef1 - HeaderHeight;

        // let changedDataCount: any = list?.reduce((count: any, event: any) => {
        //   const changedRecords = Number(event?.total_listings);
        //   return count + (changedRecords ? changedRecords : 0);
        // }, 0);

        const recalculateMarginTopTotle = () => {
          const accordion = document.getElementById(
            "inventoryaccordion"
          ) as HTMLElement;
          const heightheightRef2 = filter.offsetHeight;

          const accordionStyle = window.getComputedStyle(accordion);
          const accordionheight: any = parseFloat(
            accordionStyle.getPropertyValue("height")
          );
          const InventoryFilter: any =
            document.getElementById("InventoryFilter")?.clientHeight;
          // Recalculate MarginTopTotle based on updated heights or values
          const heightheightRef1 = heightRef1?.current?.offsetHeight;
          const HeaderHeight: any =
            document.querySelector("header")?.clientHeight;

          const bulkActionHeight: any = layout?.tableAlert?.actionTitle
            ? document.getElementById("bulk-main")?.clientHeight
            : 0;

          MarginTopTotle =
            IsToggle === true
              ? bulkActionHeight +
                heightheightRef1 +
                accordionheight -
                HeaderHeight
              : HeaderHeight - heightheightRef1;

          const eventHeaders = document.querySelectorAll(".event__header");
          const tableHead = document.querySelectorAll(".tableHead");

          // const filterPadding = stickyElement.style.paddingBottom;
          const computedStyle = window.getComputedStyle(stickyElement);

          // Get the padding-bottom value
          const filterPadding = computedStyle.paddingBottom;
          const filterValue: any = parseInt(filterPadding);

          const eventHeaderHeight =
            document.querySelector(".event__header")?.clientHeight;
          // const evtHeadTop =
          //   heightheightRef2 + InventoryFilter + HeaderHeight + filterValue;
          let evtHeadTop: any = 0;

          const headingOne =
            document.getElementById("headingOne")?.clientHeight;

          if (window.matchMedia("(max-width: 767px)").matches) {
            evtHeadTop = HeaderHeight + headingOne;
          } else {
            evtHeadTop =
              heightheightRef2 + InventoryFilter + HeaderHeight + filterValue;
          }

          // Iterate through each element and apply the style
          eventHeaders.forEach((eventHeader: any) => {
            setTimeout(() => {
              eventHeader.style.top = evtHeadTop - 1 + "px";
            }, 10);
          });
          const mkt_elem: any = document.querySelectorAll(
            ".inventory-list .inv_acccc"
          );

          list?.map((event: any) => {
            let marketElement: any = document.getElementById(
              `EventInsightCollapseBody${event?.id}`
            );
            let mkt_height = marketElement?.clientHeight;
            let bodyElement: any = document.getElementById(
              `loadHead${event?.id}`
            );

            if (bodyElement && bodyElement?.classList?.contains("mkt-open")) {
              bodyElement.style.top =
                evtHeadTop + mkt_height + eventHeaderHeight - 2 + "px";
            } else if (bodyElement) {
              bodyElement.style.top = evtHeadTop + eventHeaderHeight - 2 + "px";
            }
          });

          mkt_elem.forEach((mkt_elem: any) => {
            setTimeout(() => {
              mkt_elem.style.top = evtHeadTop + eventHeaderHeight - 2 + "px";
            }, 10);
          });
        };

        const makeSticky = function () {
          const bodyHeight = calculateBodyTotalHeight();
          setHeight(bodyHeight);
          const HeaderHeight: any =
            document.querySelector("header")?.clientHeight;

          recalculateMarginTopTotle();

          if (window.scrollY + HeaderHeight - 6 >= initialPosition) {
            stickyElement.classList.add("sticky");
            // accordion?.classList.add("!hidden");
            stickyElement.style.top =
              IsToggle === true
                ? `-${MarginTopTotle}px`
                : `${MarginTopTotle}px`;
          } else {
            stickyElement.classList.remove("sticky");
            stickyElement.style.top = `0px`;
          }
        };
        const handleWindowResize = () => {
          // Handle logic when the window is resized
          makeSticky(); // Update sticky behavior based on new values
          recalculateMarginTopTotle(); // Recalculate MarginTopTotle on window resize
        };
        handleWindowResize();

        // if (window.scrollY > 200) {
        //   makeSticky();
        // }

        const pmicons = document.querySelector(
          ".inventory-page .PMicons button"
        );
        const mktTooltips = document.querySelectorAll(".mkt-btn");

        window.addEventListener("scroll", makeSticky);
        window.addEventListener("change", makeSticky);
        window.addEventListener("blur", makeSticky);
        if (pmicons) {
          pmicons.addEventListener("click", function () {
            setTimeout(() => {
              makeSticky();
            }, 100);
          });
        }

        mktTooltips.forEach((mktTooltip: any) => {
          mktTooltip.addEventListener("click", function () {
            setTimeout(() => {
              makeSticky();
            }, 500);
          });
        });

        // if (mktTooltip) {
        //   mktTooltip.addEventListener("click", function () {
        //     setTimeout(() => {
        //       makeSticky();
        //     }, 500);
        //   });
        // }

        setTimeout(() => {
          document.addEventListener("resize", handleWindowResize);
        }, 200);
        return () => {
          window.removeEventListener("scroll", makeSticky);
          window.removeEventListener("change", makeSticky);
          window.removeEventListener("blur", makeSticky);
          document.removeEventListener("resize", handleWindowResize);
          pmicons?.removeEventListener("click", makeSticky);
        };
      }
    }, [
      toggleRef,
      IsToggle,
      overViewHeight,
      isLoading,
      coreLayout?.htmlFontSize,
      list,
      layout?.tableAlert,
    ]);

    useEffect(() => {
      if (list && layout?.tableAlert?.confirm) {
        const result = list?.map((obj: any) =>
          obj?.eventRecords?.some(
            (innerobj: any) => innerobj?.selected === true
          )
        );
        const selectedEvent = result?.some((item: any) => item === true);

        if (selectedEvent === false) {
          layout.setTableAlert({});
        }
      }
    }, [list]);

    // useEffect(() => {
    //   const topFilter: any =
    //     document.getElementById("topFilters")?.clientHeight;
    //   const EventAccordionelem: any = document.getElementById("EventAccordion");
    //   const EventAccordion: any =
    //     document.getElementById("EventAccordion")?.clientHeight;
    //   const inventoryaccordion: any =
    //     document.getElementById("inventoryaccordion")?.clientHeight;
    //   if (EventAccordion < window.innerHeight + topFilter) {
    //     EventAccordionelem.style.marginBottom = inventoryaccordion + "px";
    //   }
    // }, [])

    // console.log(layout?.tableAlert);
    const selectedRef: any = useRef<any>();
    const teamMemberSelectedRef: any = useRef<any>();
    const publishSelectedRef: any = useRef<any>();

    // HANDLE SELECT-DESELECT FOT TEAMS
    const handleOnSelection = (
      data: any,
      fieldName: string,
      options: any,
      visibleOptions: any = null,
      isArrayType: any,
      setState: any
    ) => {
      if (selectedRef && selectedRef?.current) {
        selectedRef.current.blur();
      }
      if (teamMemberSelectedRef && teamMemberSelectedRef?.current) {
        teamMemberSelectedRef.current.blur();
      }
      if (ticketTypeRef && ticketTypeRef?.current) {
        ticketTypeRef.current.blur();
      }
      if (ticketStatusRef && ticketStatusRef?.current) {
        ticketStatusRef.current.blur();
      }
      if (publishSelectedRef && publishSelectedRef?.current) {
        publishSelectedRef.current.blur();
      }
      if (listingRef && listingRef?.current) {
        listingRef.current.blur();
      }
      if (categoryRef && categoryRef?.current) {
        categoryRef.current.blur();
      }
      setPagination((prev: any) => {
        return {
          ...prev,
          page: 1,
        };
      });
      if (fieldName === "teamMembers") {
        let updatedOptions =
          visibleOptions && size(visibleOptions) > 0
            ? visibleOptions
            : data?.target?.id === "select-all"
            ? options
            : [];

        if (data?.target?.id === "select-all") {
          setState((currentValue: any) => {
            return addSelectAll(currentValue, updatedOptions, fieldName);
          });
        } else {
          setState((currentValue: any) => {
            return removeSelectAll(currentValue, updatedOptions);
          });
        }
      } else {
        handleSelectDeselectAll(
          data,
          fieldName,
          options,
          visibleOptions,
          setState,
          isArrayType
        );
      }
    };

    const [inventoryOption, setInventoryOption] = useState<any>();

    useEffect(() => {
      const fetchInventoryOption = async () => {
        try {
          const option = await getInventoryOption();
          if (option) {
            setInventoryOption((pre: any) => {
              const ticketType = getProperTicketTypeWithSubticketCombine(
                option?.data?.ticket_types
              );
              const newRenameTicketType = ticketType?.map((obj: any) => {
                return {
                  ...obj,
                  name: ticketTypeRename(obj?.id),
                };
              });

              const idToNameMap: any = {
                2: "Price good",
                1: "Price okay",
                3: "Price high",
                0: "Price low",
              };

              option?.data?.price_quality?.forEach((item: any) => {
                item.name = idToNameMap?.[item.id];
              });

              const quality_levels = option?.data?.quality_levels
                ? [
                    ...(option?.data?.quality_levels ?? []),
                    ...(option?.data?.price_quality ?? []), // hold suggestion price
                  ]
                : [];
              const qualityIconMap: any = {
                Low: low,
                "Level 1": level1,
                "Level 2": level2,
                "Level 3": level3,
                "Level 4 (Max)": level4,
                "Price low": lowwithcolor,
                "Price good": level4,
                "Price high": high,
                "Price okay": average,
              };
              const quality_levels_icon = quality_levels?.map((obj: any) => {
                let icon = qualityIconMap[obj?.name];
                if (obj?.name === "Level 4 (Max)") {
                  obj.name = "Level 4";
                }
                return {
                  ...obj,
                  icon: icon,
                };
              });
              const ticketStatus = [
                { id: "yes", name: "Tickets in hand" },
                { id: true, name: "Tickets uploaded" },
              ];

              return {
                ...pre,
                quality_levels: quality_levels_icon,
                ticket_types: newRenameTicketType,
                ticketStatus: ticketStatus,
                categories: option?.data?.categories,
              };
            });
          }

          // Now you can use the 'option' data as needed.
        } catch (error) {
          console.error("Error fetching inventory options:", error);
        }
      };

      fetchInventoryOption();
    }, []);

    function ToggleElemClose() {
      const submenuWrapper: any = document.querySelector("#filtersMob");
      submenuWrapper.classList.remove("activeFIlter");
      document.body.classList.remove("overflow-hidden");
    }
    function ToggleElemOpen() {
      console.log("object");
      const submenuWrapper: any = document.querySelector("#filtersMob");
      submenuWrapper.classList.add("activeFIlter");
      document.body.classList.add("overflow-hidden");
    }
    const clearDisable =
      (!userSpecific && users && users.length > 0) ||
      (publishStatus && publishStatus?.length > 0) ||
      (dateRange && dateRange?.target?.value) ||
      inventoryFilterHandle?.event_id?.length > 0 ||
      teamsSelected?.length > 0 ||
      layout.inventoryNewFilterValue?.ticket_type ||
      layout.inventoryNewFilterValue?.in_hand ||
      layout.inventoryNewFilterValue?.is_ticket_uploaded ||
      layout.inventoryNewFilterValue?.listing_quality?.length > 0 ||
      layout.inventoryNewFilterValue?.ticketstatus?.length > 0 ||
      query ||
      inventoryFilterHandle?.debouncedSearch;

    const handleEventSearch = (): void => {
      const isMobileView = window.matchMedia("(max-width: 768px)").matches;
      if (inputChange && isMobileView) {
        setInventoryFilterHandle((pre: any) => {
          return {
            ...pre,
            page: 1,
            per_page: isGodAdmin ? 25 : 50,
            debouncedSearch: inputChange,
          };
        });
      }
    };

    return (
      <React.Fragment key={uniqeKey}>
        <div
          className={`topFilters max-md:static md:sticky sticky-element top-[60px] z-[12] pb-4 bg-gray-200 inv_topfilter max-md:pt-[2.375rem]`}
          id="topFilters"
          ref={filterRef}
        >
          <div
            className="accordion font-medium max-md:fixed max-md:top-[3.75rem] max-md:left-0 max-md:w-full max-md:z-30 bg-white"
            ref={heightRef1}
          >
            <div className="accordion-item !rounded-none bg-white border-b border-gray-200">
              <div
                className="flex  justify-between accordion-header mb-0"
                id="headingOne"
              >
                <div className="flex flex-wrap justify-between w-full pl-2 pr-5 py-2">
                  <HeaderfilterContainer
                    list={list}
                    setList={setList}
                    bulkActionRef={bulkActionRef}
                    isTableDisabled={isTableDisabled}
                  />
                  {isAddInventory && !isGodAdmin && (
                    <a href="../add-inventory">
                      <div className="flex mt-1 md:mt-0 ml-2 md:ml-0 sticky max-md:hidden">
                        <button
                          type="button"
                          className="relative inline-flex ml-auto md:h-6  items-center z-10 text-white bg-violet-500 hover:bg-indigo-500 transition font-semibold rounded text-sm13 px-2.5 py-0.5 uppercase after:content-[''] after:h-2/4 after:w-full after:bg-violet-400 after:absolute after:top-0 after:left-0 after:rounded after:transition hover:after:opacity-0 after:-z-10"
                        >
                          <KTSVG
                            className="min-w-[.75rem] max-w-[.75rem] mr-2 -mt-0.5 flex items-center justify-center fill-white"
                            path="other_icons/plus.svg"
                          />
                          Add Inventory
                        </button>
                      </div>
                    </a>
                  )}
                </div>
                <div>
                  <button
                    className="collapse-icon group h-full group relative rounded-tr flex items-center py-4 px-[.9375rem] text-base text-gray-800 text-left bg-white border-l transition focus:outline-none group ml-auto [&[aria-expanded='false'] > .icon ]:rotate-180 "
                    type="button"
                    data-te-collapse-init
                    aria-expanded="true"
                    data-te-target={`#inventoryaccordion`}
                    aria-controls="inventoryaccordion"
                    onClick={() =>
                      setIsToggle(IsToggle === false ? true : false)
                    }
                  >
                    <KTSVG
                      className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition group-[[data-te-collapse-collapsed]]:rotate-180"
                      path="other_icons/Collapse.svg"
                      svgClassName="w-[.5625rem] h-[.5625rem]"
                    />
                  </button>
                </div>
              </div>
            </div>
            {/* {Object.keys(layout?.tableAlert).length &&
            layout?.tableAlert?.confirm ? (
              <BulkOperations
                list={list}
                setList={setList}
                confirmedBulkAction={confirmedBulkAction}
                teamsOptions={teamsOptions}
                isGodAdmin={isGodAdmin}
                loadMoreListing={loadMoreListing}
              />
            ) : (
              ""
            )} */}
          </div>

          <div className="bg-white shadow-md accordion-collapse font-medium">
            {Object.keys(layout?.tableAlert).length &&
            layout?.tableAlert?.confirm ? (
              <BulkOperations
                list={list}
                setList={setList}
                confirmedBulkAction={confirmedBulkAction}
                teamsOptions={teamsOptions}
                isGodAdmin={isGodAdmin}
                loadMoreListing={loadMoreListing}
              />
            ) : (
              ""
            )}

            {/*Inventory Header*/}
            <InventoryHeader
              listCountingInfo={listCountingInfo}
              publishStatus={publishStatus}
              disabled={isTableDisabled ? true : false}
              setPublishStatus={setPublishStatus}
              isLoading={shimmerLoader}
            />

            {/*second layer*/}
            <div
              className="filtersMob max-md:flex max-md:flex-col"
              id="filtersMob"
            >
              <div
                className={`flex flex-wrap md:border-b border-t gap-y-2.5 py-4 px-[.9375rem] bg-white max-md:pt-14 max-md:flex-1 max-md:overflow-auto max-md:content-start
                  ${isTableDisabled ? "pointer-events-none" : ""} `}
                id={`filters_block`}
                ref={heightRef2}
              >
                <div
                  className="closePopup hidden max-md:flex"
                  id="closePopup"
                  onClick={ToggleElemClose}
                >
                  <IKTSVG
                    path={close}
                    className="w-10 absolute top-[1px] right-0 h-10 flex items-center justify-center cursor-pointer z-[1] bg-white"
                    svgClassName="w-4 h-4"
                  />
                </div>
                {/* MULTI SELECT - SEARCH FIELD */}
                <div
                  className={`w-full lg2:w-1/3 lg:w-1/2 xl:w-[25%] px-[.3125rem] z-[3]`}
                >
                  <div
                    className={`relative flex-1 w-full md:mb-0 mb-2 rounded false ${
                      shimmerLoader && "shimmer-effect"
                    }`}
                  >
                    <div className="w-full  ">
                      <MultiSelect
                        name="event_id"
                        options={algoliaOptions}
                        isLoading={algoliaLoading}
                        handleOnChange={(data: any, e: any) => {
                          setInventoryFilterHandle((currentValue: any) => {
                            return {
                              ...currentValue,
                              ...(e?.action !== "pop-value"
                                ? { [data?.target?.name]: data?.target?.value }
                                : {}),
                            };
                          });
                        }}
                        onMenuScrollToBottom={(data: any) => {
                          setInventoryFilterHandle((currentValue: any) => {
                            let currentPage = currentValue?.algoliaPage + 1;
                            if (algoliaData?.nbPages - 1 >= currentPage) {
                              return {
                                ...currentValue,
                                ...(currentValue?.algoliaPage
                                  ? {
                                      algoliaPage:
                                        currentValue?.algoliaPage + 1,
                                    }
                                  : { algoliaPage: 1 }),
                              };
                            } else {
                              return { ...currentValue };
                            }
                          });
                        }}
                        handleKeyDown={(event: any) => handleKeyDown(event)}
                        value={inventoryFilterHandle?.event_id}
                        inputValue={inputChange}
                        handleInputChange={handleInputChange}
                        onSearch={(data: any) => onSearch(data)}
                        classNamePrefix="inventory_select"
                        className="searchbar-wrap"
                        placeholder={"Search event or listing ID"}
                      />
                    </div>
                  </div>
                </div>

                {/* TEAMS */}
                {isGodAdmin &&
                  layout.filterItems[`${getFilterIndexForCheckbox(1)}`]
                    ?.checked && (
                    <div
                      className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                        1
                      )}  `}
                    >
                      <div className={`${shimmerLoader && "shimmer-effect"} `}>
                        <MultiDropDownField
                          isCapital={true}
                          isValueCapital={true}
                          options={teamsOptions}
                          placeholder={
                            teamsSelected?.length > 0 ? "Teams" : "All Teams"
                          }
                          classNamePrefix={"form_multiDropDown"}
                          isClearable={true}
                          name="team_id"
                          handleOnChange={(data: any) => {
                            setTeamsSelected(data?.target?.value);
                            layout.setTeamSelectedGlobal(data?.target?.value);

                            if (
                              _.size(data?.target?.value) === 0 &&
                              _.size(users) > 0
                            ) {
                              layout.setUsers("");
                            }
                          }}
                          handleOnSelection={(data: any, visibleOptions: any) =>
                            handleOnSelection(
                              data,
                              "team_id",
                              teamsOptions,
                              visibleOptions,
                              true,
                              setTeamsSelected
                            )
                          }
                          selectedRef={selectedRef}
                          isMultiSelection={true}
                          value={teamsSelected}
                          label="Teams"
                        />
                      </div>
                    </div>
                  )}

                {/* TEAM MEMBER -  MULTIDROPDOWN */}
                {!userSpecific &&
                  layout.filterItems[`${getFilterIndexForCheckbox(2)}`]
                    ?.checked && (
                    <div
                      className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                        2
                      )} `}
                    >
                      <div className={`${shimmerLoader && "shimmer-effect"} `}>
                        <MultiDropDownField
                          options={layout.teamMembers}
                          value={users}
                          // defaultValue={defaultUsers}
                          name="teamMembers"
                          classNamePrefix={"form_multiDropDown"}
                          placeholder="Team members"
                          selectedRef={teamMemberSelectedRef}
                          isMultiSelection={true}
                          handleOnSelection={(data: any, visibleOptions: any) =>
                            handleOnSelection(
                              data,
                              "teamMembers",
                              layout.teamMembers,
                              visibleOptions,
                              true,
                              layout.setUsers
                            )
                          }
                          handleOnChange={(data: any) => {
                            setPagination((prev: any) => {
                              return {
                                ...prev,
                                page: 1,
                              };
                            });
                            setCustomLoading(true);
                            layout.setUsers(
                              data.target.value
                                ? data.target.value
                                : (users.id = "all")
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}

                {/* EVENT DATE */}
                {layout.filterItems[`${getFilterIndexForCheckbox(3)}`]
                  .checked && (
                  <div
                    className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                      3
                    )} `}
                  >
                    <div className={`${shimmerLoader && "shimmer-effect"} `}>
                      <DatePickerField
                        name="dateRange"
                        isDateRange={true}
                        placeholder="Event date"
                        disabledKeyboardNavigation={false}
                        // value={new Date()}
                        value={dateRange?.target?.value}
                        handleOnChange={(data: any) => {
                          setPagination((prev: any) => {
                            return {
                              ...prev,
                              page: 1,
                            };
                          });
                          setDateRange(data);
                        }}
                        format="dd/MM/yy"
                      />
                    </div>
                  </div>
                )}

                {/* Tickets type */}
                {layout.filterItems?.[`${getFilterIndexForCheckbox(5)}`]
                  ?.checked && (
                  <div
                    className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                      5
                    )} `}
                  >
                    <div className={`${shimmerLoader && "shimmer-effect"} `}>
                      <MultiDropDownField
                        options={inventoryOption?.ticket_types}
                        classNamePrefix={"form_multiDropDown"}
                        isClearable={true}
                        placeholder="Ticket type"
                        value={layout.inventoryNewFilterValue?.ticket_type}
                        name="ticket_type"
                        getOptionValue={"name"}
                        isMultiSelection={true}
                        selectedRef={ticketTypeRef}
                        handleOnSelection={(data: any, visibleOptions: any) =>
                          handleOnSelection(
                            data,
                            "ticket_type",
                            inventoryOption?.ticket_types,
                            visibleOptions,
                            false,
                            layout.setInventoryNewFilterValue
                          )
                        }
                        handleOnChange={(data: any) => {
                          layout.setInventoryNewFilterValue((pre: any) => {
                            return {
                              ...pre,
                              [data.target.name]: data.target.value,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                )}
                {/* TICKET STATUS */}
                {layout.filterItems?.[`${getFilterIndexForCheckbox(6)}`]
                  ?.checked && (
                  <div
                    className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                      6
                    )} `}
                  >
                    <div className={`${shimmerLoader && "shimmer-effect"} `}>
                      {/* <Checkbox
                    label="Tickets in hand"
                    id="in_hand"
                    name="in_hand"
                    labelclass="text-gray-500"
                    checked={layout.inventoryNewFilterValue?.in_hand}
                    tooltip={false}
                    handleOnChange={(data: any) => {
                      layout.setInventoryNewFilterValue((pre: any) => {
                        return {
                          ...pre,
                          [data.target.name]: data.target.checked,
                        };
                      });
                    }}
                  /> */}
                      <MultiDropDownField
                        options={inventoryOption?.ticketStatus}
                        classNamePrefix={"form_multiDropDown"}
                        isClearable={true}
                        value={layout.inventoryNewFilterValue?.ticketstatus}
                        // placeholder="Publish status"
                        placeholder="Ticket status"
                        name="ticketstatus"
                        selectedRef={ticketStatusRef}
                        isMultiSelection={true}
                        handleOnSelection={(data: any, visibleOptions: any) =>
                          handleOnSelection(
                            data,
                            "ticketstatus",
                            inventoryOption?.ticketStatus,
                            visibleOptions,
                            false,
                            layout.setInventoryNewFilterValue
                          )
                        }
                        handleOnChange={(data: any) => {
                          layout.setInventoryNewFilterValue((pre: any) => {
                            return {
                              ...pre,
                              [data.target.name]: data.target.value,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                )}

                {/* Tickets uploaded */}
                {/* {layout.filterItems?.[`${getFilterIndexForCheckbox(7)}`]
                ?.checked && (
                <div
                  className={`w-full md:min-w-[11.4375rem] md:max-w-[11.4375rem] rounded order-${getFilterIndex(
                    7
                  )} ${shimmerLoader && "shimmer-effect"}`}
                >
                  <Checkbox
                    label="Tickets uploaded"
                    id="is_ticket_uploaded"
                    name="is_ticket_uploaded"
                    labelclass="text-gray-500"
                    checked={layout.inventoryNewFilterValue?.is_ticket_uploaded}
                    tooltip={false}
                    handleOnChange={(data: any) => {
                      layout.setInventoryNewFilterValue((pre: any) => {
                        return {
                          ...pre,
                          [data.target.name]: data.target.checked,
                        };
                      });
                    }}
                  />
                </div>
              )} */}

                {/* PUBLISH STATUS */}
                {layout.filterItems?.[`${getFilterIndexForCheckbox(7)}`]
                  ?.checked && (
                  <div
                    className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                      7
                    )} `}
                  >
                    <div className={`${shimmerLoader && "shimmer-effect"} `}>
                      <MultiDropDownField
                        options={PUBLISH_STATUS}
                        classNamePrefix={"form_multiDropDown"}
                        isClearable={true}
                        value={publishStatus}
                        // placeholder="Publish status"
                        placeholder="Listing status"
                        name="publishstatus"
                        selectedRef={publishSelectedRef}
                        isMultiSelection={true}
                        handleOnSelection={(data: any, visibleOptions: any) =>
                          handleOnSelection(
                            data,
                            "publishstatus",
                            PUBLISH_STATUS,
                            visibleOptions,
                            true,
                            setPublishStatus
                          )
                        }
                        handleOnChange={(data: any) => {
                          setPagination((prev: any) => {
                            return {
                              ...prev,
                              page: 1,
                            };
                          });
                          setPublishStatus(data.target.value);
                        }}
                      />
                    </div>
                  </div>
                )}
                {/* listing quality */}
                {layout.filterItems?.[`${getFilterIndexForCheckbox(8)}`]
                  ?.checked && (
                  <div
                    className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                      8
                    )} listing_quantity_dd`}
                  >
                    <div className={`${shimmerLoader && "shimmer-effect"} `}>
                      <MultiDropDownField
                        options={inventoryOption?.quality_levels}
                        classNamePrefix={"form_multiDropDown"}
                        isClearable={true}
                        placeholder="Listing quality"
                        value={layout.inventoryNewFilterValue?.listing_quality}
                        name="listing_quality"
                        getOptionValue={"name"}
                        isMultiSelection={true}
                        selectedRef={listingRef}
                        handleOnSelection={(data: any, visibleOptions: any) =>
                          handleOnSelection(
                            data,
                            "listing_quality",
                            inventoryOption?.quality_levels,
                            visibleOptions,
                            false,
                            layout.setInventoryNewFilterValue
                          )
                        }
                        handleOnChange={(data: any) => {
                          layout.setInventoryNewFilterValue((pre: any) => {
                            return {
                              ...pre,
                              [data.target.name]: data.target.value,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                )}

                {/* CATEGORY */}
                {layout.filterItems?.[`${getFilterIndexForCheckbox(9)}`]
                  ?.checked && (
                  <div
                    className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                      9
                    )} `}
                  >
                    <div className={`${shimmerLoader && "shimmer-effect"} `}>
                      <MultiDropDownField
                        options={inventoryOption?.categories}
                        classNamePrefix={"form_multiDropDown"}
                        isClearable={true}
                        placeholder="Category"
                        value={layout.inventoryNewFilterValue?.category_ids}
                        name="category_ids"
                        isMultiSelection={true}
                        selectedRef={ticketTypeRef}
                        handleOnSelection={(data: any, visibleOptions: any) =>
                          handleOnSelection(
                            data,
                            "category_ids",
                            inventoryOption?.categories,
                            visibleOptions,
                            false,
                            layout.setInventoryNewFilterValue
                          )
                        }
                        handleOnChange={(data: any) => {
                          layout.setInventoryNewFilterValue((pre: any) => {
                            return {
                              ...pre,
                              [data.target.name]: data.target.value,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="bottomBtns hidden max-md:flex sticky bottom-0 left-0 w-full px-5 py-2.5 shadow-3xl">
                <button
                  className={`border  hover:border-indigo-500  hover:text-white  hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5 ${
                    clearDisable
                      ? "bg-white text-violet-800 border-gray-300"
                      : "bg-gray-100 text-gray-400 pointer-events-none border-gray-100"
                  }`}
                  onClick={() => {
                    setCustomLoading(true);
                    !userSpecific && layout.setUsers([]);
                    setPublishStatus([]);
                    setInventoryFilterHandle((current: any) => {
                      return {
                        event_id: [],
                        debouncedSearch: "",
                      };
                    });
                    layout.setInventoryNewFilterValue({});
                    setQuery("");
                    setDateRange({ name: "dateRange", value: "" });
                    setInputChange("");
                    setTeamsSelected([]);
                  }}
                >
                  Clear
                </button>

                <button
                  className="border border-violet-500 hover:border-indigo-500 text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
                  onClick={() => {
                    ToggleElemClose();
                    handleEventSearch();
                  }}
                >
                  Apply
                </button>
              </div>
            </div>

            {/*third layer*/}
            <div
              className={`flex flex-wrap min-h-[3.375rem] ${
                isTableDisabled ? "pointer-events-none" : ""
              }`}
              id={`InventoryFilter`}
            >
              <div
                className={`py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center  max-sm:w-[calc(100%-9rem)]`}
              >
                {shimmerLoader ? (
                  <div className="shimmer-effect inline-block rounded">
                    <span className="opacity-0">
                      {paginateData?.total > 0
                        ? `${addCommaInNumber(paginateData?.total)} events`
                        : "0 event"}
                    </span>
                  </div>
                ) : (
                  <>
                    {paginateData?.total > 0
                      ? `${addCommaInNumber(paginateData?.total)} events`
                      : "0 event"}
                  </>
                )}
              </div>

              <div
                className={`flex items-center justify-start px-5 py-3 gap-x-2.5 flex-1 filterChipArea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full max-sm:flex-1 max-sm:order-3 max-sm:border-t max-sm:justify-star`}
              >
                {shimmerLoader ? (
                  <div className="flex gap-5">
                    <div
                      className={`flex !rounded overflow-hidden ${
                        shimmerLoader && "shimmer-effect"
                      }`}
                      style={{ height: "1.625rem", width: "9.375rem" }}
                    >
                      &nbsp;
                    </div>
                  </div>
                ) : (
                  <>
                    {((!userSpecific && users && users.length > 0) ||
                      (publishStatus && publishStatus?.length > 0) ||
                      (dateRange && dateRange?.target?.value) ||
                      inventoryFilterHandle?.event_id?.length > 0 ||
                      teamsSelected?.length > 0 ||
                      layout.inventoryNewFilterValue?.ticket_type ||
                      layout.inventoryNewFilterValue?.in_hand ||
                      layout.inventoryNewFilterValue?.is_ticket_uploaded ||
                      layout.inventoryNewFilterValue?.listing_quality?.length >
                        0 ||
                      layout.inventoryNewFilterValue?.category_ids?.length >
                        0 ||
                      layout.inventoryNewFilterValue?.ticketstatus?.length >
                        0 ||
                      query ||
                      inventoryFilterHandle?.debouncedSearch) && (
                      <button
                        type="button"
                        className={`group border text-center bg-white rounded min-w-[1.5rem] w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition  ${
                          shimmerLoader && "shimmer-effect"
                        }`}
                        id="reset"
                        onClick={() => {
                          setCustomLoading(true);
                          !userSpecific && layout.setUsers([]);
                          setPublishStatus([]);
                          setInventoryFilterHandle((current: any) => {
                            return {
                              event_id: [],
                              debouncedSearch: "",
                            };
                          });
                          layout.setInventoryNewFilterValue({});
                          setQuery("");
                          setDateRange({ name: "dateRange", value: "" });
                          setInputChange("");
                          setTeamsSelected([]);
                        }}
                        // title="Reset"
                      >
                        <Tooltip
                          anchorId={`reset`}
                          content={`Reset`}
                          place="top"
                          variant="light"
                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 "
                        />
                        <KTSVG
                          path="other_icons/refresh.svg"
                          className="fill-violet-500 group-hover:fill-white transition"
                          svgClassName="mx-[.313rem] w-3 h-3"
                        />
                      </button>
                    )}

                    {/*  TEAM LISTING  */}
                    {teamsSelected?.length > 0 &&
                      teamsSelected.map((user: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                              {user?.name}
                              <button
                                type="button"
                                className="ml-2"
                                onClick={(e: any) => {
                                  setCustomLoading(true);
                                  setTeamsSelected((currentVal: any) =>
                                    currentVal.filter(
                                      (user: any, indexNumber: number) =>
                                        index !== indexNumber
                                    )
                                  );
                                }}
                              >
                                <KTSVG
                                  path="standard_icons/cross.svg"
                                  svgClassName="h-2 w-2 hover:fill-indigo-500"
                                />
                              </button>
                            </div>
                          </React.Fragment>
                        );
                      })}

                    {layout.inventoryNewFilterValue?.ticketstatus?.length > 0 &&
                      layout.inventoryNewFilterValue.ticketstatus?.map(
                        (user: any, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                                {user?.name}
                                <button
                                  type="button"
                                  className="ml-2"
                                  onClick={(e: any) => {
                                    layout.setInventoryNewFilterValue(
                                      (currentVal: any) => {
                                        let ticketstatus =
                                          currentVal?.ticketstatus?.filter(
                                            (user: any, indexNumber: number) =>
                                              index !== indexNumber
                                          );
                                        return {
                                          ...currentVal,
                                          ticketstatus: ticketstatus,
                                        };
                                      }
                                    );
                                  }}
                                >
                                  <KTSVG
                                    path="standard_icons/cross.svg"
                                    svgClassName="h-2 w-2 hover:fill-indigo-500"
                                  />
                                </button>
                              </div>
                            </React.Fragment>
                          );
                        }
                      )}

                    {/* USER LISTING  */}
                    {!userSpecific &&
                      users &&
                      users.length > 0 &&
                      users.map((user: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <div
                              className={`flex py-1 px-2.5 text-xs border rounded-full my-0.5 ${
                                shimmerLoader && "shimmer-effect"
                              }  `}
                            >
                              <span
                                className={`${shimmerLoader && "opacity-0"}`}
                              >
                                {user?.name}
                              </span>
                              <button
                                type="button"
                                className="ml-2"
                                onClick={(e: any) => {
                                  setCustomLoading(true);
                                  layout.setUsers((currentVal: any) =>
                                    currentVal.filter(
                                      (user: any, indexNumber: number) =>
                                        index !== indexNumber
                                    )
                                  );
                                }}
                              >
                                <KTSVG
                                  path="standard_icons/cross.svg"
                                  svgClassName="h-2 w-2 hover:fill-indigo-500"
                                />
                              </button>
                            </div>
                          </React.Fragment>
                        );
                      })}

                    {/* USER ENTERED STRING */}
                    {(debouncedSearch ||
                      inventoryFilterHandle?.debouncedSearch) && (
                      <div
                        className={`${
                          query || inventoryFilterHandle?.debouncedSearch
                            ? "flex py-1 px-2.5 text-xs border rounded-full"
                            : "hidden"
                        } `}
                      >
                        {query || inventoryFilterHandle?.debouncedSearch}
                        <button
                          type="button"
                          className="ml-2"
                          onClick={() => {
                            setInputChange("");
                            // setQuery("");
                            setInventoryFilterHandle((currentVal: any) => {
                              return {
                                ...currentVal,
                                debouncedSearch: "",
                              };
                            });
                          }}
                        >
                          <KTSVG
                            path="standard_icons/cross.svg"
                            svgClassName="h-2 w-2 hover:fill-indigo-500"
                          />
                        </button>
                      </div>
                    )}

                    {/* ALOGLIA SELECTED */}
                    {inventoryFilterHandle?.event_id &&
                      inventoryFilterHandle?.event_id?.length > 0 &&
                      inventoryFilterHandle?.event_id?.map(
                        (item: any, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                                {item?.name}
                                <button
                                  type="button"
                                  className="ml-2"
                                  onClick={(e: any) => {
                                    setCustomLoading(true);
                                    setInventoryFilterHandle(
                                      (currentVal: any) => {
                                        let event_id =
                                          currentVal?.event_id?.filter(
                                            (user: any, indexNumber: number) =>
                                              index !== indexNumber
                                          );
                                        return {
                                          ...currentVal,
                                          event_id: event_id,
                                        };
                                      }
                                    );
                                  }}
                                >
                                  <KTSVG
                                    path="standard_icons/cross.svg"
                                    svgClassName="h-2 w-2 hover:fill-indigo-500"
                                  />
                                </button>
                              </div>
                            </React.Fragment>
                          );
                        }
                      )}

                    {/* PUBLISH SELECTED  */}
                    {publishStatus &&
                      publishStatus?.length > 0 &&
                      publishStatus?.map((item: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="flex py-1 px-2.5 text-xs border rounded-full">
                              {item?.name}
                              <button
                                type="button"
                                className="ml-2"
                                onClick={(e: any) => {
                                  setCustomLoading(true);
                                  setPublishStatus((currentVal: any) =>
                                    currentVal.filter(
                                      (user: any, indexNumber: number) =>
                                        index !== indexNumber
                                    )
                                  );
                                }}
                              >
                                <KTSVG
                                  path="standard_icons/cross.svg"
                                  svgClassName="h-2 w-2 hover:fill-indigo-500"
                                />
                              </button>
                            </div>
                          </React.Fragment>
                        );
                      })}

                    {/* DATE RANGE */}
                    {dateRange && dateRange?.target?.value && (
                      <div className="flex py-1 px-2.5 text-xs border rounded-full">
                        {`${moment(dateRange?.target?.value?.startDate).format(
                          "DD MMM YYYY"
                        )} - ${moment(dateRange?.target?.value?.endDate).format(
                          "DD MMM YYYY"
                        )}`}
                        <button
                          type="button"
                          className="ml-2"
                          onClick={(e: any) => {
                            setCustomLoading(true);
                            setDateRange({ name: "dateRange", value: "" });
                          }}
                        >
                          <KTSVG
                            path="standard_icons/cross.svg"
                            svgClassName="h-2 w-2 hover:fill-indigo-500"
                          />
                        </button>
                      </div>
                    )}

                    {/*  ticket type */}
                    {layout.inventoryNewFilterValue?.ticket_type?.length > 0 &&
                      layout.inventoryNewFilterValue?.ticket_type?.map(
                        (obj: any, index: any) => {
                          return (
                            <div className="flex py-1 px-2.5 !capitalize text-xs border rounded-full my-0.5">
                              {obj?.name}
                              <button
                                type="button"
                                className="ml-2"
                                onClick={(e: any) => {
                                  layout.setInventoryNewFilterValue(
                                    (currentVal: any) => {
                                      let ticket_type =
                                        currentVal?.ticket_type?.filter(
                                          (user: any, indexNumber: number) =>
                                            index !== indexNumber
                                        );
                                      return {
                                        ...currentVal,
                                        ticket_type: ticket_type,
                                      };
                                    }
                                  );
                                }}
                              >
                                <KTSVG
                                  path="standard_icons/cross.svg"
                                  svgClassName="h-2 w-2 hover:fill-indigo-500"
                                />
                              </button>
                            </div>
                          );
                        }
                      )}

                    {/*  listing quality */}
                    {layout.inventoryNewFilterValue?.listing_quality?.length >
                      0 &&
                      layout.inventoryNewFilterValue?.listing_quality?.map(
                        (obj: any, index: any) => {
                          return (
                            <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                              {obj?.name}
                              <button
                                type="button"
                                className="ml-2"
                                onClick={(e: any) => {
                                  setCustomLoading(true);

                                  layout.setInventoryNewFilterValue(
                                    (currentVal: any) => {
                                      if (!currentVal?.listing_quality) {
                                        return currentVal; // If listing_quality is not defined, return the current value
                                      }

                                      // Create a new array by filtering out the item with the specified index
                                      const updatedListingQuality =
                                        currentVal.listing_quality.filter(
                                          (_: any, indexNumber: number) =>
                                            index !== indexNumber
                                        );

                                      return {
                                        ...currentVal, // Keep other properties intact
                                        listing_quality: updatedListingQuality, // Update listing_quality with the filtered array
                                      };
                                    }
                                  );
                                }}
                              >
                                <KTSVG
                                  path="standard_icons/cross.svg"
                                  svgClassName="h-2 w-2 hover:fill-indigo-500"
                                />
                              </button>
                            </div>
                          );
                        }
                      )}

                    {/*  in hand */}
                    {layout.inventoryNewFilterValue?.in_hand && (
                      <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                        {"In hand"}
                        <button
                          type="button"
                          className="ml-2"
                          onClick={(e: any) => {
                            setCustomLoading(true);
                            layout.setInventoryNewFilterValue?.(
                              (currentVal: any) => ({
                                ...currentVal,
                                in_hand: null,
                              })
                            );
                          }}
                        >
                          <KTSVG
                            path="standard_icons/cross.svg"
                            svgClassName="h-2 w-2 hover:fill-indigo-500"
                          />
                        </button>
                      </div>
                    )}

                    {/*  ticket upload */}
                    {layout.inventoryNewFilterValue?.is_ticket_uploaded && (
                      <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                        {"Ticket uploaded"}
                        <button
                          type="button"
                          className="ml-2"
                          onClick={(e: any) => {
                            setCustomLoading(true);
                            layout.setInventoryNewFilterValue?.(
                              (currentVal: any) => ({
                                ...currentVal,
                                is_ticket_uploaded: null,
                              })
                            );
                          }}
                        >
                          <KTSVG
                            path="standard_icons/cross.svg"
                            svgClassName="h-2 w-2 hover:fill-indigo-500"
                          />
                        </button>
                      </div>
                    )}

                    {/* CATEGORT - FILTERCHIP */}
                    {layout.inventoryNewFilterValue?.category_ids?.length > 0 &&
                      layout.inventoryNewFilterValue?.category_ids.map(
                        (user: any, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                                {user?.name}
                                <button
                                  type="button"
                                  className="ml-2"
                                  onClick={(e: any) => {
                                    layout.setInventoryNewFilterValue(
                                      (currentVal: any) => {
                                        let category_ids =
                                          currentVal?.category_ids?.filter(
                                            (user: any, indexNumber: number) =>
                                              index !== indexNumber
                                          );
                                        return {
                                          ...currentVal,
                                          category_ids: category_ids,
                                        };
                                      }
                                    );
                                  }}
                                >
                                  <KTSVG
                                    path="standard_icons/cross.svg"
                                    svgClassName="h-2 w-2 hover:fill-indigo-500"
                                  />
                                </button>
                              </div>
                            </React.Fragment>
                          );
                        }
                      )}
                  </>
                )}
              </div>

              <div
                className="filterBtn max-md:flex max-md:items-center hidden p-2.5 text-sm12 border-l max-sm:order-2 max-sm:max-w-[9rem]"
                id="filterBtn"
                onClick={ToggleElemOpen}
              >
                <button className="group inline-flex group items-center text-white hover:text-white bg-violet-500  hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5">
                  <IKTSVG
                    path={filter}
                    className="fill-white mr-1.5"
                    svgClassName="w-4 h-4"
                  />
                  Filter results
                </button>
              </div>

              {/* Pagination */}
              <InventoryPagination
                paginateData={paginateData}
                isGodAdmin={isGodAdmin}
                uniqueKey={10}
                isBulkActive={layout.tableAlert?.confirm ? true : false}
                shimmerLoader={shimmerLoader}
                handleOnChange={(data: any) => {
                  if (startListing) {
                    setEventId([]);
                    let page = data?.selected
                      ? parseInt(data?.selected) + 1
                      : data?.target?.name === "current-page-number"
                      ? data?.target?.value
                      : 1;
                    let viewPage =
                      data?.target?.name === "per_page" && data?.target?.value;
                    if (page) {
                      setTimeout(() => {
                        setPagination((pre: any) => {
                          return {
                            ...pre,
                            per_page: viewPage
                              ? viewPage
                              : pre?.per_page && pre.per_page,
                            page: !Number.isNaN(page) && page !== 0 ? page : 1,
                          };
                        });
                      }, 500);
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
);

export default InventoryFilter;
