import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import SearchSVG from "../../../assets/media/icons/standard_icons/search-violet.svg";
import Checkbox from "../../../components/formComponent/Checkbox";
import DatePickerField from "../../../components/formComponent/DatePickerField";
import DropDownField from "../../../components/formComponent/DropDownField";
import MultiDropDownField from "../../../components/formComponent/MultiDropDownField";
import MultiSelect from "../../../components/formComponent/MultiSelect";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { handleSelectDeselectAll } from "../../../helpers/Functions";
import { QUERIES } from "../../../helpers/const";
import useDebounce from "../../add_inventory/core/useDebounce";
import { TxAccountContext } from "../core/TxAccountProvider";
import { algoliaSearch } from "../core/_request";

const CommonFilter = ({
  handleOnChange,
  options,
  value,
  inputChange,
  setInputChange,
  loading,
}: any) => {
  const layout = useContext(TxAccountContext);
  const [algoliaOptions, setAlgoliaOptions] = useState<any>();
  const algoliaDebounce = useDebounce(inputChange, 1200);

  // HEADER FILTER
  const getFilterItems = () =>
    layout.filterItems?.filter((obj: any) => obj?.checked === true);

  // ON CLEAR BUTTON - ALGOLIA
  const onSearch = (data: any) => {
    if (data?.target?.id !== "search") {
      setInputChange("");
      setAlgoliaOptions([]);
    }
  };

  // ALGOLIA SEARCH
  useQuery(
    [`${QUERIES.ALGOLIA_TxACCOUNT}`, algoliaDebounce],
    () => {
      if (inputChange && inputChange.trim() !== "") {
        return algoliaSearch({ inputChange });
      }
      return Promise.resolve();
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: true,
      enabled: algoliaDebounce ? true : false,
      onError: (err) => {},
      onSettled(data, error) {
        setAlgoliaOptions(data?.hits);
      },
    }
  );

  // ALGOLIA HANDLE CHANGE
  const handleInputChange = (data: any, event: any) => {
    if (
      event.action === "menu-close" ||
      event.action === "set-value" ||
      event.action === "input-blur"
    ) {
      if (!data) {
        setInputChange((current: any) => {
          return current;
        });
      }
    } else {
      setInputChange((current: any) => {
        if (algoliaDebounce !== data) {
          setAlgoliaOptions([]);
        }
        return data;
      });
    }
  };

  // HANDLE SELECT-DESELECT FOT TEAMS
  const handleOnSelection = (
    data: any,
    fieldName: string,
    options: any,
    visibleOptions: any,
    searchName: string = "name"
  ) => {
    // MAP WHICH IS STATE
    const filterSetterMap: any = {
      Orders: layout.setOrderFilters,
      Invoices: layout.setInvoicesFilter,
      "Pay outs": layout.setPayOutFilter,
      "Seller wallets": layout.setSellerWalletsFilter,
      "Bank accounts": layout.setBankAccountFilter,
      "Withdraw requests": layout.setWithdrawRequestFilter,
    };

    const filterSetter = filterSetterMap[layout.isTabActive];

    handleSelectDeselectAll(
      data,
      fieldName,
      options,
      visibleOptions,
      filterSetter,
      false,
      searchName,
      [],
      [{ name: "id" }]
    );
  };

  return (
    <React.Fragment>
      {(layout?.isTabActive === "Orders" ||
        layout?.isTabActive === "Invoices") && (
        <div className={`w-full lg2:w-1/3 lg:w-1/2 xl:w-[25%]  px-[.3125rem]`}>
          {/* SEARCH BAR */}
          <div
            className={`relative rounded flex-1 max-w-full md:mb-0 ${
              loading && "shimmer-effect"
            } `}
          >
            <div
              className="w-full  "
              title={
                layout?.isTabActive === "Orders"
                  ? "Search event or order ID or Marketplace ID"
                  : "Search invoice number"
              }
            >
              {layout?.isTabActive === "Orders" ? (
                <MultiSelect
                  name="event_id"
                  classNamePrefix="inventory_select"
                  className="searchbar-wrap"
                  options={algoliaOptions}
                  handleOnChange={(data: any, e: any) => {
                    layout.setOrderFilters((currentValue: any) => {
                      return {
                        ...currentValue,
                        ...(e?.action !== "pop-value"
                          ? { [data?.target?.name]: data?.target?.value }
                          : {}),
                        page: 1,
                        per_page: 20,
                      };
                    });
                  }}
                  handleKeyDown={(event: any) => {
                    if (event?.target?.value && event.key === "Enter") {
                      event.preventDefault();
                      let eventObj = {
                        target: {
                          name: "query",
                          value: event?.target?.value,
                        },
                      };
                      handleOnChange(eventObj);
                    }
                  }}
                  value={layout.orderFilters?.event_id}
                  inputValue={inputChange}
                  handleInputChange={handleInputChange}
                  onSearch={(data: any) => onSearch(data)}
                  placeholder="Search event or order ID or Marketplace ID"
                />
              ) : (
                layout?.isTabActive === "Invoices" && (
                  <React.Fragment>
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <button type="button">
                        <IKTSVG
                          className="fill-violet-500"
                          svgClassName="h-3.5 w-3.5"
                          path={SearchSVG}
                        />
                      </button>
                    </div>
                    <input
                      type="text"
                      name="query"
                      defaultValue={""}
                      id="simple-search"
                      placeholder="Search invoice number"
                      className="bg-indigo-500/5 border font-medium border-indigo-500/10 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-[1.875rem] pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
                      onKeyDown={(event: any) => {
                        if (event?.target?.value && event.key === "Enter") {
                          event.preventDefault();
                          let eventObj = {
                            target: {
                              name: "query",
                              value: event?.target?.value,
                            },
                          };
                          handleOnChange(eventObj);
                        }
                      }}
                    />
                  </React.Fragment>
                )
              )}
            </div>
          </div>
        </div>
      )}

      {getFilterItems()?.map((columnItem: any, index: number) => {
        switch (columnItem.id) {
          case 1:
            return (
              <React.Fragment key={index}>
                {/* Team */}
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] min-w-[13.125rem] px-[.3125rem]   `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"}  `}>
                    <MultiDropDownField
                      classNamePrefix={"form_multiDropDown"}
                      placeholder="Teams"
                      options={options?.options_teams}
                      name="teams"
                      value={value?.teams}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                      handleOnSelection={(data: any, visibleOptions: any) =>
                        handleOnSelection(
                          data,
                          "teams",
                          options?.options_teams,
                          visibleOptions
                        )
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            );

          case 2:
            return (
              <React.Fragment key={index}>
                {/* Marketplaces */}
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
                >
                  <div className={`rounded ${loading && "shimmer-effect"}`}>
                    {layout?.isTabActive !== "Invoices" ? (
                      <DropDownField
                        placeholder="Marketplaces"
                        options={options?.options_marketplace}
                        name="marketplace"
                        value={value?.marketplace}
                        isClearable={true}
                        id="marketplace"
                        handleOnChange={(data: any) => {
                          handleOnChange(data);
                        }}
                      />
                    ) : (
                      <MultiDropDownField
                        classNamePrefix={"form_multiDropDown"}
                        placeholder="Marketplaces"
                        options={options?.options_marketplace}
                        name="marketplace"
                        value={value?.marketplace}
                        handleOnChange={(data: any) => {
                          handleOnChange(data);
                        }}
                        handleOnSelection={(data: any, visibleOptions: any) =>
                          handleOnSelection(
                            data,
                            "marketplace",
                            options?.options_marketplace,
                            visibleOptions
                          )
                        }
                      />
                    )}
                  </div>
                </div>
              </React.Fragment>
            );
          case 3:
            return (
              <React.Fragment key={index}>
                {/* Transaction date */}
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem]   `}
                >
                  <div className={` rounded  ${loading && "shimmer-effect"} `}>
                    <DatePickerField
                      name="transcation_date"
                      placeholder="Transaction date"
                      disabledKeyboardNavigation={false}
                      isDateRange={true}
                      value={value?.transcation_date}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                      format="dd/MM/yy"
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          case 4:
            return (
              <React.Fragment key={index}>
                {/*event date*/}
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem]  `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"}  `}>
                    <DatePickerField
                      name="event_date"
                      placeholder="Event date"
                      disabledKeyboardNavigation={false}
                      isDateRange={true}
                      value={value?.event_date}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                      format="dd/MM/yy"
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          case 5:
            return (
              <React.Fragment key={index}>
                {/* order stauts */}
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"}  `}>
                    <MultiDropDownField
                      classNamePrefix={"form_multiDropDown"}
                      placeholder="Order status"
                      options={options?.options_orderstatus}
                      name="order_status"
                      value={value?.order_status}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                      getOptionValue="id"
                      getOptionLabel="description"
                      handleOnSelection={(data: any, visibleOptions: any) =>
                        handleOnSelection(
                          data,
                          "order_status",
                          options?.options_orderstatus,
                          visibleOptions,
                          "id"
                        )
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          case 6:
            return (
              <React.Fragment key={index}>
                {/* internal order stauts */}
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
                >
                  <div className={` rounded  ${loading && "shimmer-effect"} `}>
                    <MultiDropDownField
                      classNamePrefix={"form_multiDropDown"}
                      placeholder="Internal order status"
                      options={options?.options_internalorderstatus}
                      name="internal_order_status"
                      value={value?.internal_order_status}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                      handleOnSelection={(data: any, visibleOptions: any) =>
                        handleOnSelection(
                          data,
                          "internal_order_status",
                          options?.options_internalorderstatus,
                          visibleOptions
                        )
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          case 7:
            return (
              <React.Fragment key={index}>
                {/* Payment status */}
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]   `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"}  `}>
                    <MultiDropDownField
                      classNamePrefix={"form_multiDropDown"}
                      placeholder="Seller payment status"
                      options={options?.options_paymentstatus}
                      name="payment_status"
                      value={value?.payment_status}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                      handleOnSelection={(data: any, visibleOptions: any) =>
                        handleOnSelection(
                          data,
                          "payment_status",
                          options?.options_paymentstatus,
                          visibleOptions
                        )
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          case 8:
            return (
              <React.Fragment key={index}>
                {/* includ web store*/}
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"} `}>
                    <Checkbox
                      label="Include Webstores"
                      id="store_id"
                      name="store_id"
                      labelclass="text-gray-500"
                      checked={value?.store_id}
                      tooltip={false}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          case 9:
            return (
              <React.Fragment key={index}>
                {/* Invoices date*/}
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"} `}>
                    <DatePickerField
                      name="invoices_date"
                      placeholder="Invoices date"
                      disabledKeyboardNavigation={false}
                      isDateRange={true}
                      value={value?.invoices_date}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                      format="dd/MM/yy"
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          case 10:
            return (
              <React.Fragment key={index}>
                {/* Payment date */}
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"} `}>
                    <DatePickerField
                      name="payment_date"
                      placeholder="Payment date"
                      disabledKeyboardNavigation={false}
                      isDateRange={true}
                      value={value?.payment_date}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                      format="dd/MM/yy"
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          case 11:
            return (
              <React.Fragment key={index}>
                {/* Invoice status */}
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
                >
                  <div className={` rounded  ${loading && "shimmer-effect"} `}>
                    <MultiDropDownField
                      classNamePrefix={"form_multiDropDown"}
                      placeholder="Invoice status"
                      options={options?.options_invoice_statuses}
                      name="invoice_status"
                      value={value?.invoice_status}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                      handleOnSelection={(data: any, visibleOptions: any) =>
                        handleOnSelection(
                          data,
                          "invoice_status",
                          options?.options_invoice_statuses,
                          visibleOptions
                        )
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            );

          case 12:
            return (
              <React.Fragment key={index}>
                {/* Pay out date */}
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"}  `}>
                    <DatePickerField
                      name="pay_out_date"
                      placeholder=" Pay out date"
                      disabledKeyboardNavigation={false}
                      isDateRange={true}
                      value={value?.pay_out_date}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                      format="dd/MM/yy"
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          case 13:
            return (
              <React.Fragment key={index}>
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] min-w-[13.125rem] px-[.3125rem]  `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"}  `}>
                    <DropDownField
                      placeholder="Marketplaces payment status"
                      options={options?.options_marketplace_paymentstatus}
                      name="marketplaces_payment_status"
                      value={value?.marketplaces_payment_status}
                      isClearable={true}
                      id="marketplaces_payment_status"
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                      handleOnSelection={(data: any, visibleOptions: any) =>
                        handleOnSelection(
                          data,
                          "marketplaces_payment_status",
                          options?.options_marketplace_paymentstatus,
                          visibleOptions
                        )
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          case 14:
            return (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
              >
                <div className={` rounded  ${loading && "shimmer-effect"} `}>
                  <MultiDropDownField
                    classNamePrefix={"form_multiDropDown"}
                    placeholder="Wallet status"
                    options={options?.options_walletstatus}
                    name="statuses"
                    value={value?.statuses}
                    handleOnChange={(data: any) => {
                      handleOnChange(data);
                    }}
                    handleOnSelection={(data: any, visibleOptions: any) =>
                      handleOnSelection(
                        data,
                        "statuses",
                        options?.options_walletstatus,
                        visibleOptions
                      )
                    }
                  />
                </div>
              </div>
            );
          case 15:
            return (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
              >
                <div className={` rounded  ${loading && "shimmer-effect"} `}>
                  <MultiDropDownField
                    classNamePrefix={"form_multiDropDown"}
                    placeholder="Withdraw request status"
                    options={options?.options_withdraw_request_statuses}
                    name="statuses"
                    value={value?.statuses}
                    handleOnChange={(data: any) => {
                      handleOnChange(data);
                    }}
                    handleOnSelection={(data: any, visibleOptions: any) =>
                      handleOnSelection(
                        data,
                        "statuses",
                        options?.options_withdraw_request_statuses,
                        visibleOptions
                      )
                    }
                  />
                </div>
              </div>
            );
          case 16:
            return (
              <React.Fragment key={index}>
                {/* Pay out date */}
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"}  `}>
                    <DatePickerField
                      name="requestedDate"
                      placeholder="Requested date"
                      disabledKeyboardNavigation={false}
                      isDateRange={true}
                      value={value?.requestedDate}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                      format="dd/MM/yy"
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          case 17:
            return (
              <React.Fragment key={index}>
                {/* MARKETPLACE CURRENCY */}
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] min-w-[13.125rem] px-[.3125rem]  `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"}  `}>
                    <DropDownField
                      placeholder="Marketplace currency"
                      options={options?.options_marketplace_currencies}
                      name="marketplace_currency"
                      value={value?.marketplace_currency}
                      isClearable={true}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                      isCapital={true}
                      isValueCapital={true}
                      handleOnSelection={(data: any, visibleOptions: any) =>
                        handleOnSelection(
                          data,
                          "marketplace_currency",
                          options?.options_marketplace_currencies,
                          visibleOptions
                        )
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          case 18:
            return (
              <React.Fragment key={index}>
                {/* MARKETPLACE CURRENCY */}
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem]  `}
                >
                  <div className={`${loading && "shimmer-effect"} rounded`}>
                    <Checkbox
                      label="Order Ids"
                      id="bulk_order"
                      name="bulk_order"
                      labelclass="text-gray-500"
                      checked={layout.orderFilters?.bulk_order}
                      tooltip={false}
                      handleOnChange={(data: any, e: any) => {
                        handleOnChange(data);
                      }}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
        }
      })}
    </React.Fragment>
  );
};

export default CommonFilter;
