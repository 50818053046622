export const CrossIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.414"
    height="11.414"
    viewBox="0 0 11.414 11.414"
    {...props}
  >
    <g
      id="Group_2505"
      data-name="Group 2505"
      transform="translate(0.707 0.707)"
    >
      <path
        id="Path_26"
        data-name="Path 26"
        d="M4,4,14,14"
        transform="translate(-4 -4)"
        fill="none"
        stroke="#6f6cff"
        stroke-width="2"
      />
      <path
        id="Path_382"
        data-name="Path 382"
        d="M0,0,10,10"
        transform="translate(10) rotate(90)"
        fill="none"
        stroke="#6f6cff"
        stroke-width="2"
      />
    </g>
  </svg>
);
