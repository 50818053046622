/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import _, { uniq } from "lodash";
import { useContext, useRef } from "react";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import TablePopup from "../../../components/tableComponent/TablePopup";
import { showAlert } from "../../../helpers/ShowAlert";
import { TicketError } from "../../add_inventory/core/_functions";
import { InventoryContext } from "../core/InventoryProvider";
import { checkValidation } from "../core/_functions";
import { EventFields } from "../core/_models";

const Bulk = ({ list, setList }: any) => {
  const layout = useContext(InventoryContext);
  const coreLayout = useContext(LayoutContext);
  const events: any = list;
  const isGodAdmin = coreLayout?.allowedAccess?.account_info?.is_god_admin;
  const btnRef: any = useRef(null);

  //Function For Handling Search
  const handleFilterSearch = (e: any) => {
    layout.setBulkItems((prev: any) => {
      let updatedArray =
        Array.isArray(prev) &&
        prev.map((item: any) => {
          if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            return {
              ...item,
              searched: true,
            };
          } else {
            return {
              ...item,
              searched: false,
            };
          }
        });
      return updatedArray;
    });
  };

  /**
   * HANDLE BULK ACTION
   */
  const handleBulkAction = (itemId: number) => {
    setTimeout(() => {
      switch (itemId) {
        case 1:
          selectBulk("publish-all");
          break;
        case 2:
          selectBulk("unpublish-all");
          break;
        case 4:
          selectBulk("delete");
          break;
        case 5:
          selectBulk("update-pricing");
          break;
        case 6:
          selectBulk("listing-transfer");
          break;
      }
    }, 200);
  };

  /**
   * SELECT BULK
   */

  const selectBulk = (action: String) => {
    let checkedIds: any = [];
    let published = 0;
    let unpublished = 0;
    events.map((event: any) => {
      return (
        Array.isArray(event["eventRecords"]) &&
        event["eventRecords"].forEach((eventRecord: EventFields) => {
          if (eventRecord?.selected === true) {
            checkedIds.push(eventRecord.id);
          }
          if (eventRecord?.selected === true && eventRecord?.status === "yes") {
            published++;
          }
          if (eventRecord?.selected === true && eventRecord?.status === "no") {
            unpublished++;
          }
        })
      );
    });
    let actionTitle =
      action === "publish-all"
        ? `publish`
        : action === `delete`
        ? `delete`
        : action === "update-pricing"
        ? "Update pricing"
        : action === "listing-transfer"
        ? "Transfer ownership"
        : `unpublish`;

    const errorMessage: any = checkValidation(
      actionTitle,
      list,
      isGodAdmin,
      layout?.deliveryType
    );
    if (errorMessage) {
      showAlert(errorMessage, true, 3000);
      setList((pre: any) => {
        return pre.map((event: any) => {
          let final = event["eventRecords"]?.map((eventRecord: any) => {
            if (
              eventRecord?.listing_ticket_files?.length > 0 &&
              Number(eventRecord?.quantity_available) !==
                Number(eventRecord?.listing_ticket_files?.length)
            ) {
              let errors: any = [
                {
                  errors: uniq([
                    ...(eventRecord?.errors && _.size(eventRecord?.errors) > 0
                      ? eventRecord?.errors?.[0]?.errors
                      : []),
                    ...(eventRecord?.fieldErrors &&
                    _.size(eventRecord?.fieldErrors) > 0
                      ? Object.keys(eventRecord?.fieldErrors)
                      : []),
                    TicketError,
                  ]),
                  exchange: eventRecord?.errors?.[0]?.exchange,
                },
              ];

              return {
                ...eventRecord,
                ...(_.size(errors?.[0]?.errors) > 0 && {
                  errors: errors,
                }),
              };
            }

            return eventRecord;
          });

          return {
            ...event,
            eventRecords: final,
          };
        });
      });

      return 0;
    } else if (checkedIds.length > 0) {
      setList((events: any) => {
        return events?.map((event: any) => {
          if (event?.requireSelectAll && _.size(event?.requireSelectAll) > 0) {
            delete event?.requireSelectAll;
            return event;
          } else {
            return event;
          }
        });
      });
      layout.setTableAlert({
        message:
          actionTitle === "delete"
            ? `Are you sure you want to ${actionTitle} ${
                checkedIds.length
              } selected listing${checkedIds.length > 1 ? "s" : ""}?  ${
                published > 0 ? published + " Published" : ""
              }${published && unpublished ? "," : ""} ${
                unpublished > 0 ? +unpublished + " Unpublished" : ""
              }`
            : `Are you sure you want to ${actionTitle} ${
                checkedIds.length
              } selected listing${checkedIds.length > 1 ? "s" : ""}?`,
        confirm: {
          checkedIds: checkedIds.join(),
          action,
        },
        actionTitle: _.capitalize(actionTitle),
      });
      let blukAction: any = document.querySelector(
        'span[data-te-target="#bulkaction3"]'
      );
      blukAction && blukAction.click();

      let bulkAction2: any = document.querySelectorAll(
        `span[data-te-target="#bulk"]:not([data-te-collapse-collapsed])`
      )?.[0];

      if (bulkAction2) {
        bulkAction2?.click();
      }
    } else {
      layout.setTableAlert({
        message: `Select a row to ${actionTitle} `,
      });
    }
  };

  return (
    <>
      {Object.keys(layout?.tableAlert).length &&
      !layout?.tableAlert?.confirm ? (
        <TablePopup
          btnRef={btnRef}
          isConfirm={layout?.tableAlert?.confirm ? true : false}
          message={layout?.tableAlert?.message}
          cancelBtn={() => layout.setTableAlert({})}
        />
      ) : (
        ""
      )}
      <div className="ba_search p-2.5 border-b">
        <input
          type="text"
          placeholder="Search bulk actions"
          className="rounded bg-violet-100/5 border-violet-100/20 w-full h-[1.875rem] text-sm12 px-2.5 py-2"
          onChange={handleFilterSearch}
        />
      </div>
      <div className="mp-block border-b">
        {/* <span className="text-sm12 text-gray-400 block font-medium px-2.5 py-1.5">
          Most popular
        </span> */}
        <ul>
          {layout.bulkItems
            .filter((item: any) => item.searched)
            .map((item: any, itemKey: number) => {
              return (
                <li
                  className="font-medium hover:bg-indigo-500/5 hover:text-indigo-500"
                  onClick={() => handleBulkAction(item.id)}
                  key={itemKey}
                >
                  <a
                    // href="#"
                    className={`px-2.5 py-1.5 text-sm12 font-medium block bg-white transition ${
                      item.id === 4
                        ? `hover:bg-rose-500/5 hover:text-rose-500`
                        : `hover:bg-indigo-500/5 `
                    }`}
                  >
                    {item.name}
                  </a>
                </li>
              );
            })}
        </ul>
      </div>
      {/* <div className="ba-bottom-dd">
                <ul>
                    <li className="border-b">
                        <span className="group px-2.5 py-1.5 text-sm12 font-medium relative block [&[data-te-collapse-collapsed]]:bg-white bg-gray-100 transition"
                            data-te-collapse-init
                            data-te-collapse-collapsed
       
                            data-te-target="#pricing"
                            aria-expanded="false"
                            aria-controls="pricing">
                            Pricing
                            <KTSVG
                                className="min-w-1.5 max-w-1.5 w-1.5 flex items-center justify-center fill-violet-500 absolute top-1/2 right-2 -translate-y-1/2 group-[[data-te-collapse-collapsed]]:rotate-0 rotate-180 transition"
                                path="other_icons/down-angle.svg"
                            />
                        </span>
                        <ul
                            id="pricing"
                            className="!visible hidden"
                            data-te-collapse-item
                            aria-labelledby="pricing">
                            <li><a href="#" className="px-2.5 py-1.5 text-sm12 font-medium block bg-white hover:bg-indigo-500/5 transition">Adjust proceed price</a></li>
                            <li><a href="#" className="px-2.5 py-1.5 text-sm12 font-medium block bg-white hover:bg-indigo-500/5 transition">Adjust face value</a></li>
                        </ul>
                    </li>
                    <li className="border-b">
                        <span className="group px-2.5 py-1.5 text-sm12 font-medium relative block [&[data-te-collapse-collapsed]]:bg-white bg-gray-100 transition"
                            data-te-collapse-init
                            data-te-collapse-collapsed
                            data-te-target="#split"
                            aria-expanded="false"
                            aria-controls="split">
                            Merge/split
                            <KTSVG
                                className="min-w-1.5 max-w-1.5 w-1.5 flex items-center justify-center fill-violet-500 absolute top-1/2 right-2 -translate-y-1/2 group-[[data-te-collapse-collapsed]]:rotate-0 rotate-180 transition"
                                path="other_icons/down-angle.svg"
                            />
                        </span>
                        <ul
                            id="split"
                            className="!visible hidden"
                            data-te-collapse-item
                            aria-labelledby="split">
                            <li><a href="#" className="px-2.5 py-1.5 text-sm12 font-medium block bg-white hover:bg-indigo-500/5 transition">Merge</a></li>
                            <li><a href="#" className="px-2.5 py-1.5 text-sm12 font-medium block bg-white hover:bg-indigo-500/5 transition">Merge all eligible</a></li>
                            <li><a href="#" className="px-2.5 py-1.5 text-sm12 font-medium block bg-white hover:bg-indigo-500/5 transition">Split</a></li>
                            <li><a href="#" className="px-2.5 py-1.5 text-sm12 font-medium block bg-white hover:bg-indigo-500/5 transition">Split to originals</a></li>
                        </ul>
                    </li>
                    <li className="border-b">
                        <span className="group px-2.5 py-1.5 text-sm12 font-medium relative block [&[data-te-collapse-collapsed]]:bg-white bg-gray-100 transition"
                            data-te-collapse-init
                            data-te-collapse-collapsed
                            data-te-target="#tags"
                            aria-expanded="false"
                            aria-controls="tags">
                            Tags/notes
                            <KTSVG
                                className="min-w-1.5 max-w-1.5 w-1.5 flex items-center justify-center fill-violet-500 absolute top-1/2 right-2 -translate-y-1/2 group-[[data-te-collapse-collapsed]]:rotate-0 rotate-180 transition"
                                path="other_icons/down-angle.svg"
                            />
                        </span>
                        <ul
                            id="tags"
                            className="!visible hidden"
                            data-te-collapse-item
                            aria-labelledby="Tags">
                            <li><a href="#" className="px-2.5 py-1.5 text-sm12 font-medium block bg-white hover:bg-indigo-500/5 transition">Merge</a></li>
                            <li><a href="#" className="px-2.5 py-1.5 text-sm12 font-medium block bg-white hover:bg-indigo-500/5 transition">Merge all eligible</a></li>
                            <li><a href="#" className="px-2.5 py-1.5 text-sm12 font-medium block bg-white hover:bg-indigo-500/5 transition">Split</a></li>
                            <li><a href="#" className="px-2.5 py-1.5 text-sm12 font-medium block bg-white hover:bg-indigo-500/5 transition">Split to originals</a></li>
                        </ul>
                    </li>
                    <li className="px-2.5 py-1.5 text-sm12 font-medium border-b">Publish status</li>
                </ul>
            </div> */}
    </>
  );
};

export default Bulk;
