import React, { useContext } from "react";
import { LayoutContext } from "../../components/core/LayoutProvider";
import { useEffect } from "react";

type props ={
    title:string
}

const PageTitle = ({title}:props) => {
  const layout = useContext(LayoutContext);
  useEffect(() => {
    layout.setTitle(title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default PageTitle;
