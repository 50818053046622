// @ts-nocheck
export const tabConditions = (
  isTabActive: string,
  filterType: string = "filter"
) => {
  const tabMapping: any = {
    Orders: filterType === "filter" ? "Orders" : "OrdersColumn",
    Invoices: filterType === "filter" ? "Invoices" : "InvoicesColumn",
    "Pay outs": filterType === "filter" ? "Pay outs" : "payoutColumn",
    "Seller wallets":
    filterType === "filter" ? "Seller wallets" : "sellerwalletColumn",
    "Bank accounts":
    filterType === "filter" ? "Bank accounts" : "bankAccountsColumn",
    "Withdraw requests":
    filterType === "filter" ? "Withdraw requests" : "withdrawRequestColumn",
  };
  
  return tabMapping[isTabActive] || "";
};

export const downloadFile = ({ data, fileName, fileType }: any) => {
  const blob = new Blob([data], { type: fileType });
  
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
  return false;
};

export const display = (isOpen: boolean) => {
  var pop_width = document.getElementsByClassName("s_ticket")?.[0]?.clientWidth;
  var body = document.querySelector("body");
  var html = document.querySelector("html");
  if (isOpen) {
    (document.getElementById("EventAccordion")?.style).marginRight = `${
      pop_width
    }px`;
    // document.body.style.overflow = 'hidden';
    // body?.classList.add("overflow-hidden");
    body?.classList.add("scrollbar-none");
    html?.classList.add("scrollbar-none");
  } else if (!isOpen) {
    (document.getElementById("EventAccordion")?.style).marginRight = `0px`;
    // document.body.style.overflow = 'unset';
    // body?.classList?.remove("overflow-hidden");
    body?.classList.add("scrollbar-none");
    html?.classList.add("scrollbar-none");
    const singleTicket = document.getElementById("singleTicket");
    if (singleTicket) {
      singleTicket.style.visibility = "hidden";
    }
  }
};