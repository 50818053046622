/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import MinusSVG from "../../.././assets/media/icons/other_icons/minus.svg";
import PlusSVG from "../../.././assets/media/icons/other_icons/plus.svg";
import CalenderSVG from "../../.././assets/media/icons/standard_icons/calendar.svg";
import { LayoutContext } from "../../core/LayoutProvider";
import DropDownField from "../../formComponent/DropDownField";
import { IKTSVG } from "../../ui/IKTSVG";

// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const Header = () => {
  const layout = useContext(LayoutContext);
  const location = useLocation();
  const todayDate = useMemo(() => moment().format("dddd, DD MMMM"), []);
  const title = useMemo(() => layout.title, [layout.title]);

  const currentFontSize: any = {
    id: layout?.htmlFontSize,
    name: layout.htmlFontSize + "%",
  };

  const zoomLevel = () => {
    const array: any = [];
    for (let index = 70; index <= 120; index = index + 10) {
      array.push({
        id: index,
        name: index + "%",
      });
    }
    return array;
  };

  const handleZoomLevel = (up: Boolean) => {
    if (up) {
      if (layout?.htmlFontSize < 120) {
        let updateFontSize = layout?.htmlFontSize + 10;
        layout.setHtmlFontSize(updateFontSize);
        localStorage.setItem("htmlFontSize", JSON.stringify(updateFontSize));
      }
    } else {
      if (layout?.htmlFontSize > 70) {
        let updateFontSize = layout?.htmlFontSize - 10;
        layout.setHtmlFontSize(updateFontSize);
        localStorage.setItem("htmlFontSize", JSON.stringify(updateFontSize));
      }
    }
  };

  useEffect(() => {
    // @ts-ignore: Unreachable code error
    document.querySelector("html").style.fontSize = layout?.htmlFontSize + "%";
  }, [layout?.htmlFontSize]);

  const calnderShow =
    layout?.isTxTradeUser === 1
      ? location.pathname === "/dashboard"
      : location.pathname === "/";

  const [chatOpen, setChatOpen] = useState<any>(false);

  const handleButtonClick = () => {
    const chatwootRef: any = document.querySelector(".woot-widget-bubble");
    if (chatwootRef) {
      chatwootRef.click();
      setChatOpen(!chatOpen);
    }
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {location.pathname === "/" ? (
            <title>
              {layout?.isTxTradeUser === 1 ? "TX Trade" : "Dashboard"}
            </title>
          ) : (
            <title>{title}</title>
          )}

          <meta name="Tixstock" content="Tixstock application" />
        </Helmet>
      </HelmetProvider>

      {/* VERSION CHECK  */}
      {/* <NotificationPopup /> */}

      <header
        className={`sticky top-0 border-b flex md:flex-nowrap flex-wrap items-center justify-between md:px-5 pr-5 pl-[4.375rem] py-4 bg-white z-[1046]  min-h-[3.9375rem]`}
      >
        <div className="text-violet-800 text-xl font-semibold ">
          <span className="capitalize">{title}</span>
          {calnderShow && (
            <span className="text-xs md:inline-flex hidden px-2 py-1 rounded bg-gray-100 items-center leading-[1] ml-4">
              <IKTSVG
                path={CalenderSVG}
                className="min-w-[0.69rem] fill-violet-800 mr-1.5"
              />
              {todayDate}
            </span>
          )}
        </div>
        {/* {layout.userDetailShow && 
        <div className="head-carousel md:flex hidden items-center justify-center font-semibold text-sm13 px-3 xxl:w-[39%] w-[47%]">
        <Carousel
          className="carousel-fade"
          infiniteLoop={true}
          autoPlay={true}
          showArrows={false}
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          interval={6000}
          swipeable={false}
          animationHandler="fade"
        >
          {layout?.allowedAccess?.header_notification &&
            layout?.allowedAccess?.header_notification.map(
              (messages: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      className={`flex flex-wrap items-center justify-center ${
                        messages?.error_code === "warning"
                          ? `text-rose-500`
                          : "text-green-600"
                      }`}
                    >
                      {/* <i className="fas fa-light fa-circle-exclamation pr-1.5  text-lg"></i> }
                      <p>
                        <span>
                          {messages?.message?.search(
                            "{{manage_inventory_link}}"
                          ) > 0 && (
                            <Link
                              to={"/inventory"}
                              className="underline mr-1"
                            >
                              Manage Inventory
                            </Link>
                          )}

                          {messages?.message?.search("{{team_cards_link}}") >
                            0 && (
                            <Link
                              to={"/settings"}
                              className="underline mr-1"
                              state={{ data: "TX Pay" }}
                            >
                              Manage Cards
                            </Link>
                          )}

                          {messages?.message?.search(
                            "{{team_exchange_link}}"
                          ) > 0 && (
                            <Link
                              to={"/settings#channel"}
                              className="underline mr-1"
                              state={{ data: "Channels" }}
                            >
                              Manage Channels
                            </Link>
                          )}

                          {messages?.message.replaceAll(/{{(.*?)}}/g, "")}
                        </span>
                      </p>
                    </div>
                  </React.Fragment>
                );
              }
            )}
        </Carousel>
        </div>
        } */}
        <div className="ml-auto flex items-center">
          {title === "TX Trade" && (
            <div className="tradePolicy text-xs md:mr-[4.625rem] mr-16">
              <a
                href="/faq/tx-trade-policy"
                className="text-indigo-500 hover:text-violet-500"
              >
                TX Trade Policy
              </a>
            </div>
          )}
          <div className="zoom-level min-w-[7.375rem]  rounded hidden md:flex items-center lg:max-w-[32%] md:max-w-[34%] ">
            {/* <button
            className="chat-btn-css relative z-10 flex mr-[.625rem] items-center w-7.9375rem] h-[1.875rem] gap-[.5625rem]  hover:text-white active: bg-green-600 text-white hover:bg-indigo-500 active:bg-indigo-500 transition font-semibold rounded text-sm13 px-2.5 py-0.5"
            onClick={handleButtonClick}
          >
            <IKTSVG path={chatIcon} svgClassName="w-[1rem] h-[.9375rem]" />
            Chat with us
          </button> */}

            <span className="text-sm13 font-medium mr-2">Zoom</span>
            <div className="flex flex-wrap bg-gray-100 min-w-[6.5rem] rounded">
              <div
                className="zoom-dd w-[3.5rem]"
                title={currentFontSize?.id + "%"}
              >
                <DropDownField
                  options={zoomLevel()}
                  value={currentFontSize}
                  handleOnChange={(e: any) => {
                    localStorage.setItem(
                      "htmlFontSize",
                      JSON.stringify(e.target.value.id)
                    );
                    layout.setHtmlFontSize(e.target.value.id);
                  }}
                  placeholder=""
                  isClearable={false}
                />
              </div>
              <div
                className={`PMicons w-[3rem] flex rounded-r overflow-hidden `}
              >
                <button
                  type="button"
                  className={`w-1/2 group hover:bg-indigo-500 ${
                    currentFontSize <= 70 ? `pointer-events-none` : ``
                  }`}
                  onClick={() => handleZoomLevel(false)}
                  disabled={currentFontSize <= 70}
                >
                  <IKTSVG
                    className="flex items-center justify-center fill-violet-500 group-hover:fill-white transition"
                    path={MinusSVG}
                    svgClassName="w-[.5625rem] h-0.5 "
                  />
                </button>
                <button
                  className={`w-1/2 group  hover:bg-indigo-500 ${
                    currentFontSize >= 120 ? `pointer-events-none` : ``
                  }`}
                  onClick={() => handleZoomLevel(true)}
                  disabled={currentFontSize >= 120}
                >
                  <IKTSVG
                    className="flex items-center justify-center fill-violet-500 group-hover:fill-white transition"
                    path={PlusSVG}
                    svgClassName="w-2.5 h-2.5 "
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <ChatwootComponent /> */}
      </header>
    </>
  );
};

export default Header;
