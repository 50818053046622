import _, { size } from "lodash";
import moment from "moment";
import { useContext } from "react";
import filter from "../../../assets/media/icons/other_icons/filter.svg";
import refresh from "../../../assets/media/icons/other_icons/refresh.svg";
import crossIcon from "../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { addCommaInNumber } from "../../../helpers/Functions";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import { TxAccountContext } from "../core/TxAccountProvider";

const CommonFilterChips = ({
  filterValue,
  filterName,
  onRemove,
  loading,
}: any) => {
  return (
    <div
      className={`clr_filter   lg2:max-w-[25%] flex-1 w-auto px-[.3125rem]  `}
    >
      <div
        className={`px-[.9375rem] pr-[1.875rem] py-[.5625rem] bg-indigo-500 bg-opacity-[7%] border border-indigo-500 rounded-xl min-w-[15.625rem] h-full relative ${
          loading && "shimmer-effect"
        } `}
      >
        <button
          type="button"
          className="group crossIcon w-[1.125rem] h-[1.125rem] rounded-full bg-white absolute top-2.5 right-2.5 flex items-center justify-center"
          onClick={onRemove}
          data-tooltip-id={`close-filter-${filterName}`}
        >
          <TooltipPortal
            id={`close-filter-${filterName}`}
            content={"Clear"}
            place="top"
            className="text-center  !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-100 max-w-[9rem] cursor-default "
          />
          <IKTSVG
            path={crossIcon}
            svgClassName="w-2 h-2 fill-violet-800 group-hover:fill-indigo-500"
          />
        </button>
        <h3 className="text-sm15 leading-5 mb-1">{filterValue}</h3>
        <span className="text-sm12 text-gray-500 leading-4 flex items-center">
          {filterName}
        </span>
      </div>
    </div>
  );
};

const FiltersChips = ({
  totalList,
  setInputChange,
  loading,
  handleOnclear,
}: any) => {
  const layout = useContext(TxAccountContext);
  const tab = layout.isTabActive;
  const orderFilters = layout.orderFilters;
  const invoiceFilters = layout.invoicesFilter;
  const payOutFilters = layout.payOutsFilter;
  const sellerWalletsFilter = layout.sellerWalletsFilter;
  const bankAccountFilter = layout.bankAccountFilter;
  const withdrawRequestFilter = layout.withdrawRequestFilter;

  // CONDITION
  const filterVisibile =
    orderFilters?.teams?.length > 0 ||
    orderFilters?.order_ids?.length > 0 ||
    _.size(orderFilters?.marketplace) > 0 ||
    (orderFilters?.transcation_date &&
      orderFilters?.transcation_date?.startDate !== undefined &&
      orderFilters?.transcation_date?.endDate !== undefined) ||
    (orderFilters?.event_date &&
      orderFilters?.event_date?.startDate !== undefined &&
      orderFilters?.event_date?.endDate !== undefined) ||
    orderFilters?.order_status?.length > 0 ||
    orderFilters?.internal_order_status?.length > 0 ||
    orderFilters?.payment_status?.length > 0 ||
    orderFilters?.store_id ||
    _.size(invoiceFilters?.marketplace) > 0 ||
    (invoiceFilters?.invoices_date &&
      invoiceFilters?.invoices_date?.startDate !== undefined &&
      invoiceFilters?.invoices_date?.endDate !== undefined) ||
    (invoiceFilters?.payment_date &&
      invoiceFilters?.payment_date?.startDate !== undefined &&
      invoiceFilters?.payment_date?.endDate !== undefined) ||
    invoiceFilters?.invoice_status?.length > 0 ||
    payOutFilters?.teams?.length > 0 ||
    (payOutFilters?.pay_out_date &&
      payOutFilters?.pay_out_date?.startDate !== undefined &&
      payOutFilters?.pay_out_date?.endDate !== undefined) ||
    _.size(orderFilters?.marketplaces_payment_status) > 0 ||
    _.size(orderFilters?.event_id) > 0 ||
    orderFilters?.marketplace_currency ||
    orderFilters?.query ||
    invoiceFilters?.query ||
    // SELLER WALLET
    size(sellerWalletsFilter?.teams) > 0 ||
    size(sellerWalletsFilter?.statuses) > 0 ||
    // BANK ACCOUNT FILTER
    size(bankAccountFilter?.teams) > 0 ||
    // WITHDRAW REQUEST
    size(withdrawRequestFilter?.teams) > 0 ||
    (tab === "Withdraw requests" && size(withdrawRequestFilter?.statuses) > 0
      ? true
      : false) ||
    (withdrawRequestFilter?.requestedDate?.startDate !== undefined &&
      withdrawRequestFilter?.requestedDate?.endDate !== undefined);

  // CLEAR ALL FILTER
  // const handleOnclear = () => {
  //   if (tab === "Orders") {
  //     layout.setOrderFilters(TX_ACCOUNT_ORDER_INITIAL_FILTER);
  //     setInputChange("");
  //   }
  //   if (tab === "Invoices") {
  //     let isQuery: any = document.getElementById("simple-search");
  //     isQuery.value = "";
  //     layout.setInvoicesFilter(TX_ACCOUNT_INVOICE_INITIAL_FILTER);
  //   }
  //   if (tab === "Pay outs") {
  //     layout.setPayOutFilter(TX_ACCOUNT_PAYOUT_INTIAL_FILTER);
  //   }
  //   if (tab === "Seller wallets") {
  //     layout.setSellerWalletsFilter(TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER);
  //   }
  //   if (tab === "Bank accounts") {
  //     layout.setBankAccountFilter(TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER);
  //   }
  //   if (tab === "Withdraw requests") {
  //     layout.setWithdrawRequestFilter((current: any) => {
  //       return {
  //         ...TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER,
  //         statuses: null,
  //       };
  //     });
  //   }
  // };

  const checkFilterTypeTab = (filterType: any, tab: string) => {
    let filter: any;
    if (tab === "Orders") {
      filter = orderFilters;
    } else if (tab === "Invoices") {
      filter = invoiceFilters;
    } else if (tab === "Pay outs") {
      filter = payOutFilters;
    } else if (tab === "Seller wallets") {
      filter = sellerWalletsFilter;
    } else if (tab === "Bank accounts") {
      filter = bankAccountFilter;
    } else if (tab === "Withdraw requests") {
      filter = withdrawRequestFilter;
    }
    switch (filterType) {
      case "teams":
        return filter?.teams && size(filter?.teams) > 0 && filter?.teams;
      case "order_status":
        return (
          filter?.order_status &&
          size(filter?.order_status) > 0 &&
          filter?.order_status
        );
      case "statuses":
        return (
          filter?.statuses && size(filter?.statuses) > 0 && filter?.statuses
        );

      default:
        break;
    }
  };

  // HANDLE REMOVE FILTER
  const handleFilterRemove = (tab: string, data: any, dataValue: any) => {
    const setFilter = (filterSetter: Function) => {
      filterSetter((currentVal: any) => ({
        ...currentVal,
        [`${data}`]: dataValue,
        page: 1,
      }));
    };

    switch (tab) {
      case "Orders":
        setFilter(layout.setOrderFilters);
        break;
      case "Pay outs":
        setFilter(layout.setPayOutFilter);
        break;
      case "Seller wallets":
        setFilter(layout.setSellerWalletsFilter);
        break;
      case "Bank accounts":
        setFilter(layout.setBankAccountFilter);
        break;
      case "Withdraw requests":
        setFilter(layout.setWithdrawRequestFilter);
        break;
      default:
        break;
    }
  };

  // GET TOTAL FUNCTION
  const getTotal = () => {
    return tab === "Orders"
      ? `${totalList ? addCommaInNumber(totalList) : "0"} orders`
      : tab === "Invoices"
      ? `${totalList ? addCommaInNumber(totalList) : "0"} invoices`
      : tab === "Pay outs"
      ? `${totalList ? addCommaInNumber(totalList) : "0"} statements`
      : tab === "Seller wallets" ||
        tab === "Withdraw requests" ||
        tab === "Bank accounts"
      ? `${totalList ? addCommaInNumber(totalList) : "0"} results`
      : "";
  };
  function ToggleElemOpen() {
    console.log("object");
    const submenuWrapper: any = document.querySelector("#filtersMob");
    submenuWrapper.classList.add("activeFIlter");
    document.body.classList.add("overflow-hidden");
  }

  return (
    <>
      {filterVisibile ? (
        <>
          <div className="search-filter-result px-2.5 py-3.5 flex flex-nowrap w-full overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full flex-1">
            <div className="clr_filter   lg2:max-w-[25%] flex-1 w-auto px-[.3125rem]">
              <div
                className={`px-[.9375rem] py-[.5625rem] bg-gray-100/50 border rounded-xl md:min-w-[15.625rem] min-w-[11.25rem] h-full ${
                  loading && "shimmer-effect rounded"
                }`}
              >
                <h3 className={`text-sm15 leading-5 mb-1 font-semibold  `}>
                  {/* <span className={`${loading && "shimmer-effect"}`}> */}
                  <span className={`${loading && "opacity-0"}`}>
                    {getTotal()}
                  </span>
                  {/* </span> */}
                </h3>
                <span
                  className="text-sm12 leading-4 flex items-center cursor-pointer w-[5.625rem]"
                  onClick={handleOnclear}
                >
                  <IKTSVG
                    path={refresh}
                    className=" mr-1.5"
                    svgClassName="w-[.6875rem] h-[.6875rem] fill-violet-500 hover:fill-indigo-500"
                  />
                  Clear filters
                </span>
              </div>
            </div>

            {/* SEARCHED EVENT - ALGOLIA */}
            {orderFilters?.event_id?.length > 0 &&
              orderFilters?.event_id?.[0] && (
                <CommonFilterChips
                  filterValue={
                    size(orderFilters?.event_id) > 0
                      ? size(orderFilters?.event_id) + " selected"
                      : orderFilters?.event_id?.[0]?.name
                  }
                  filterName={"Searched Event"}
                  onRemove={() => {
                    setInputChange("");
                    layout.setOrderFilters((currentVal: any) => {
                      return {
                        ...currentVal,
                        event_id: [],
                      };
                    });
                  }}
                  loading={loading}
                />
              )}

            {/* QUERY */}
            {orderFilters?.query && (
              <CommonFilterChips
                filterValue={orderFilters?.query}
                filterName={"Searched value"}
                onRemove={() => {
                  setInputChange("");
                  layout.setOrderFilters((currentVal: any) => {
                    return {
                      ...currentVal,
                      query: "",
                    };
                  });
                }}
                loading={loading}
              />
            )}
            {invoiceFilters?.query && (
              <CommonFilterChips
                filterValue={invoiceFilters?.query}
                filterName={"Searched value"}
                onRemove={() => {
                  let isQuery: any = document.getElementById("simple-search");
                  isQuery.value = "";
                  layout.setInvoicesFilter((currentVal: any) => {
                    return {
                      ...currentVal,
                      query: "",
                    };
                  });
                }}
                loading={loading}
              />
            )}

            {/* MARKETPLACE  */}
            {_.size(orderFilters?.marketplace) > 0 && (
              <CommonFilterChips
                filterValue={orderFilters?.marketplace?.name}
                filterName={"Marketplaces"}
                onRemove={() => {
                  layout.setOrderFilters((currentVal: any) => {
                    return {
                      ...currentVal,
                      marketplace: null,
                    };
                  });
                }}
                loading={loading}
              />
            )}

            {/* TRANSACTION DATE */}
            {orderFilters?.transcation_date &&
              orderFilters?.transcation_date?.startDate !== undefined &&
              orderFilters?.transcation_date?.endDate !== undefined && (
                <CommonFilterChips
                  filterValue={`${moment(
                    orderFilters?.transcation_date?.startDate
                  ).format("DD/MM/YYYY")} - ${moment(
                    orderFilters?.transcation_date?.endDate
                  ).format("DD/MM/YYYY")} `}
                  filterName={"Transaction date"}
                  onRemove={() => {
                    layout.setOrderFilters((currentVal: any) => {
                      return {
                        ...currentVal,
                        transcation_date: "",
                      };
                    });
                  }}
                  loading={loading}
                />
              )}

            {/* EVENT DATE */}
            {orderFilters?.event_date &&
              orderFilters?.event_date?.startDate !== undefined &&
              orderFilters?.event_date?.endDate !== undefined && (
                <CommonFilterChips
                  filterValue={`${moment(
                    orderFilters?.event_date?.startDate
                  ).format("DD/MM/YYYY")} - ${moment(
                    orderFilters?.event_date?.endDate
                  ).format("DD/MM/YYYY")} `}
                  filterName={"Event date"}
                  onRemove={() => {
                    layout.setOrderFilters((currentVal: any) => {
                      return {
                        ...currentVal,
                        event_date: "",
                      };
                    });
                  }}
                  loading={loading}
                />
              )}

            {/* ORDER STATUS */}
            {size(checkFilterTypeTab("order_status", tab)) > 0 &&
              checkFilterTypeTab("order_status", tab)?.[0] && (
                <CommonFilterChips
                  filterValue={
                    size(checkFilterTypeTab("order_status", tab)) > 1
                      ? size(checkFilterTypeTab("order_status", tab)) +
                        " selected"
                      : checkFilterTypeTab("order_status", tab)?.[0]
                          ?.description
                  }
                  filterName={"Order status"}
                  onRemove={() => handleFilterRemove(tab, "order_status", [])}
                  loading={loading}
                />
              )}

            {/* INTERNAL ORDER STATUS */}
            {size(orderFilters?.internal_order_status) > 0 &&
              orderFilters?.internal_order_status?.[0] && (
                <CommonFilterChips
                  filterValue={
                    size(orderFilters?.internal_order_status) > 1
                      ? size(orderFilters?.internal_order_status) + " selected"
                      : orderFilters?.internal_order_status?.[0]?.name
                  }
                  filterName={"Internal order status"}
                  onRemove={() => {
                    layout.setOrderFilters((currentVal: any) => {
                      return {
                        ...currentVal,
                        internal_order_status: [],
                      };
                    });
                  }}
                  loading={loading}
                />
              )}

            {/* PAYMENT STATUS */}
            {size(orderFilters?.payment_status) > 0 &&
              orderFilters?.payment_status?.[0] && (
                <CommonFilterChips
                  filterValue={
                    size(orderFilters?.payment_status) > 1
                      ? size(orderFilters?.payment_status) + " selected"
                      : orderFilters?.payment_status?.[0]?.name
                  }
                  filterName={"Seller payment status"}
                  onRemove={() => {
                    layout.setOrderFilters((currentVal: any) => {
                      return {
                        ...currentVal,
                        payment_status: [],
                      };
                    });
                  }}
                  loading={loading}
                />
              )}

            {/* STORE ID */}
            {orderFilters?.store_id && (
              <CommonFilterChips
                filterValue={"Include Webstore"}
                filterName={"Include Webstores"}
                onRemove={() => {
                  layout.setOrderFilters((currentVal: any) => {
                    return {
                      ...currentVal,
                      store_id: false,
                    };
                  });
                }}
                loading={loading}
              />
            )}

            {/* MARKETPLACE PAYMENT STATUS */}
            {_.size(orderFilters?.marketplaces_payment_status) > 0 && (
              <CommonFilterChips
                filterValue={orderFilters?.marketplaces_payment_status?.name}
                filterName={"Marketplaces payment status"}
                onRemove={() => {
                  layout.setOrderFilters((currentVal: any) => {
                    return {
                      ...currentVal,
                      marketplaces_payment_status: null,
                    };
                  });
                }}
                loading={loading}
              />
            )}
            {_.size(orderFilters?.marketplace_currency) > 0 && (
              <CommonFilterChips
                filterValue={orderFilters?.marketplace_currency?.name}
                filterName={"Marketplace currency"}
                onRemove={() => {
                  layout.setOrderFilters((currentVal: any) => {
                    return {
                      ...currentVal,
                      marketplace_currency: null,
                    };
                  });
                }}
                loading={loading}
              />
            )}

            {/* INVOICE FILTER CHIP */}
            {size(invoiceFilters?.marketplace) > 0 &&
              invoiceFilters?.marketplace?.[0] && (
                <CommonFilterChips
                  filterValue={
                    size(invoiceFilters?.marketplace) > 1
                      ? size(invoiceFilters?.marketplace) + " selected"
                      : invoiceFilters?.marketplace?.[0]?.name
                  }
                  filterName={"Marketplaces"}
                  onRemove={() => {
                    layout.setInvoicesFilter((currentVal: any) => {
                      return {
                        ...currentVal,
                        marketplace: [],
                      };
                    });
                  }}
                  loading={loading}
                />
              )}

            {/* INVOICE DATE */}
            {invoiceFilters?.invoices_date &&
              invoiceFilters?.invoices_date?.startDate !== undefined &&
              invoiceFilters?.invoices_date?.endDate !== undefined && (
                <CommonFilterChips
                  filterValue={`${moment(
                    invoiceFilters?.invoices_date?.startDate
                  ).format("DD/MM/YYYY")} - ${moment(
                    invoiceFilters?.invoices_date?.endDate
                  ).format("DD/MM/YYYY")} `}
                  filterName={"Invoices date"}
                  onRemove={() => {
                    layout.setInvoicesFilter((currentVal: any) => {
                      return {
                        ...currentVal,
                        invoices_date: "",
                      };
                    });
                  }}
                  loading={loading}
                />
              )}

            {/* PAYMENT DATE */}
            {invoiceFilters?.payment_date &&
              invoiceFilters?.payment_date?.startDate !== undefined &&
              invoiceFilters?.payment_date?.endDate !== undefined && (
                <CommonFilterChips
                  filterValue={`${moment(
                    invoiceFilters?.payment_date?.startDate
                  ).format("DD/MM/YYYY")} - ${moment(
                    invoiceFilters?.payment_date?.endDate
                  ).format("DD/MM/YYYY")} `}
                  filterName={"Payment date"}
                  onRemove={() => {
                    layout.setInvoicesFilter((currentVal: any) => {
                      return {
                        ...currentVal,
                        payment_date: "",
                        page: 1,
                      };
                    });
                  }}
                  loading={loading}
                />
              )}

            {/* INVOICE STATUS */}
            {size(invoiceFilters?.invoice_status) > 0 &&
              invoiceFilters?.invoice_status?.[0] && (
                <CommonFilterChips
                  filterValue={
                    size(invoiceFilters?.invoice_status) > 1
                      ? size(invoiceFilters?.invoice_status) + " selected"
                      : invoiceFilters?.invoice_status?.[0]?.name
                  }
                  filterName={"Invoices status"}
                  onRemove={() => {
                    layout.setInvoicesFilter((currentVal: any) => {
                      return {
                        ...currentVal,
                        invoice_status: [],
                      };
                    });
                  }}
                  loading={loading}
                />
              )}

            {/* TEAMS - CHIP */}
            {size(checkFilterTypeTab("teams", tab)) > 0 &&
              checkFilterTypeTab("teams", tab)?.[0] && (
                <CommonFilterChips
                  filterValue={
                    size(checkFilterTypeTab("teams", tab)) > 1
                      ? size(checkFilterTypeTab("teams", tab)) + " selected"
                      : checkFilterTypeTab("teams", tab)?.[0]?.name
                  }
                  filterName={"Teams"}
                  onRemove={() => handleFilterRemove(tab, "teams", [])}
                  loading={loading}
                />
              )}

            {payOutFilters?.pay_out_date &&
              payOutFilters?.pay_out_date?.startDate !== undefined &&
              payOutFilters?.pay_out_date?.endDate !== undefined && (
                <CommonFilterChips
                  filterValue={`${moment(
                    payOutFilters?.pay_out_date?.startDate
                  ).format("DD/MM/YYYY")} - ${moment(
                    payOutFilters?.pay_out_date?.endDate
                  ).format("DD/MM/YYYY")} `}
                  filterName={"Pay out date"}
                  onRemove={() => {
                    layout.setPayOutFilter((currentVal: any) => {
                      return {
                        ...currentVal,
                        pay_out_date: "",
                        page: 1,
                      };
                    });
                  }}
                  loading={loading}
                />
              )}

            {/* WALLET STATUS */}
            {_.size(checkFilterTypeTab("statuses", tab)) > 0 && (
              <CommonFilterChips
                filterValue={
                  size(checkFilterTypeTab("statuses", tab)) > 1
                    ? size(checkFilterTypeTab("statuses", tab)) + " selected"
                    : checkFilterTypeTab("statuses", tab)?.[0]?.name
                }
                filterName={
                  tab === "Seller wallets"
                    ? "Wallet status"
                    : "Withdraw request status"
                }
                onRemove={() => handleFilterRemove(tab, "statuses", null)}
                loading={loading}
              />
            )}

            {/* REQUESTED DATE */}
            {withdrawRequestFilter?.requestedDate &&
              withdrawRequestFilter?.requestedDate?.startDate !== undefined &&
              withdrawRequestFilter?.requestedDate?.endDate !== undefined && (
                <CommonFilterChips
                  filterValue={`${moment(
                    withdrawRequestFilter?.requestedDate?.startDate
                  ).format("DD/MM/YYYY")} - ${moment(
                    withdrawRequestFilter?.requestedDate?.endDate
                  ).format("DD/MM/YYYY")} `}
                  filterName={"Requested date"}
                  onRemove={() => {
                    layout.setWithdrawRequestFilter((currentVal: any) => {
                      return {
                        ...currentVal,
                        requestedDate: null,
                        page: 1,
                        per_page: 20,
                      };
                    });
                  }}
                  loading={loading}
                />
              )}

            {/* ORDER IDS */}
            {size(orderFilters?.order_ids) > 0 &&
              orderFilters?.order_ids?.[0] && (
                <CommonFilterChips
                  filterValue={
                    size(orderFilters?.order_ids) > 1
                      ? size(orderFilters?.order_ids) + " selected"
                      : orderFilters?.order_ids?.[0]
                  }
                  filterName={"Order Ids"}
                  onRemove={() => {
                    layout.setOrderFilters((currentVal: any) => {
                      return {
                        ...currentVal,
                        bulk_order: false,
                        order_ids: [],
                      };
                    });
                  }}
                  loading={loading}
                />
              )}
          </div>
          <div
            className="filterBtn max-md:flex max-md:items-center hidden p-2.5 text-sm12 border-l max-sm:order-2 max-sm:max-w-[9rem]"
            id="filterBtn"
            onClick={ToggleElemOpen}
          >
            <button className="group inline-flex group items-center text-white hover:text-white bg-violet-500  hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5">
              <IKTSVG
                path={filter}
                className="fill-white mr-1.5"
                svgClassName="w-4 h-4"
              />
              Filter results
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center max-sm:w-[calc(100%-9rem)]">
            <span className={`${loading && "shimmer-effect"}`}>
              <span className={`${loading && "opacity-0"}`}>{getTotal()}</span>
            </span>
          </div>
          <div className="flex items-center justify-center sm:justify-start px-5 py-3 gap-x-2.5 flex-1 filterChipArequestedDatea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:min-h-[3.375rem] max-sm:flex-1 max-sm:order-3 max-sm:border-t max-sm:justify-star max-sm:hidden"></div>
          <div
            className="filterBtn max-md:flex max-md:items-center hidden p-2.5 text-sm12 border-l max-sm:order-2 max-sm:max-w-[9rem]"
            id="filterBtn"
            onClick={ToggleElemOpen}
          >
            <button className="group inline-flex group items-center text-white hover:text-white bg-violet-500  hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5">
              <IKTSVG
                path={filter}
                className="fill-white mr-1.5"
                svgClassName="w-4 h-4"
              />
              Filter results
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default FiltersChips;
