import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RefreshSVG from "../../assets/media/icons/other_icons/refresh-right.svg";
import CalendraSVG from "../../assets/media/icons/standard_icons/calendar.svg";
import "../../assets/styles/datepicker.scss";
import { IKTSVG } from "../ui/IKTSVG";

const DatePickerField = ({
  value,
  name,
  handleOnChange,
  placeholder,
  isDateRange = false,
  disabledKeyboardNavigation = true,
  isDisabled = false,
  error = false,
  intializeField,
  maxDate = null,
  portalId = "root",
  placeholderMoveTop = false,
}: any) => {
  const [startDate, setStartDate] = useState<any>(
    value ? moment(value).toDate() : ""
  );
  const [endDate, setEndDate] = useState(null);
  const [startTempDate, setStartTempDate] = useState(value);
  const [endTempDate, setEndTempDate] = useState(value);
  const [focus, setFocus] = useState(false);
  const calRef: any = React.useRef();
  useEffect(() => {
    if (intializeField === name) {
      calRef.current.setOpen(true);
    }
  }, [intializeField]);
  return (
    <div
      className={`date_h relative group ${focus && `dp-focused`} bg-white ${
        isDisabled &&
        `bg-gray-100 pointer-events-none border-none text-gray-400 placeholder:text-gray-400 disable`
      } rounded`}
    >
      <span
        className={`z-[1] table-date-icon absolute left-0 top-0 flex items-center justify-center fill-violet-800 rounded-l border-r border-gray-300 w-8 h-full pointer-events-none group-[.dp-focused]:bg-indigo-500 group-[.dp-focused]:z-[1] group-[.dp-focused]:!fill-white group-[.dp-focused]:border-indigo-500 group-hover:fill-indigo-500 transition ${
          error ? `border-rose-500 text-rose-500` : ``
        } ${isDisabled && `!fill-gray-400 bg-gray-100`}`}
      >
        <IKTSVG svgClassName="h-3 w-3" path={CalendraSVG} />
      </span>

      {placeholder && placeholderMoveTop && (
        <div className={`dateplaceholder hover:text-indigo-500`}>
          {placeholder}
        </div>
      )}

      <DatePicker
        portalId={portalId}
        selected={value ? moment(value).toDate() : null}
        startDate={startDate}
        endDate={endDate}
        selectsRange={isDateRange}
        placeholderText={placeholder}
        ref={calRef}
        onClickOutside={(data: any) => {
          if (isDateRange && startDate && endDate === null) {
            setStartDate(null);
          }
        }}
        disabledKeyboardNavigation={disabledKeyboardNavigation}
        shouldCloseOnSelect={false}
        dateFormat={isDateRange ? "dd MMM yyyy" : "dd/MM/yyyy"}
        onChange={(date: any) => {
          if (isDateRange) {
            const [start, end] = date;
            setStartDate(start);
            setEndTempDate(end);
            let event = {
              target: { name: name, value: { startDate: start, endDate: end } },
            };
            handleOnChange(event);
          } else {
            setStartTempDate(date);
          }
        }}
        popperProps={{
          strategy: "fixed", // use this to make the popper position: fixed
        }}
        onCalendarOpen={() => setFocus(true)}
        onCalendarClose={() => setFocus(false)}
        useWeekdaysShort={true}
        calendarStartDay={1}
        preventOpenOnFocus={true}
        className={`block px-2.5 font-medium w-full max-w-[6.875rem] h-8 text-xs transition bg-transparent border border-gray-300 appearance-none rounded focus:outline-none focus:ring-0 focus:border-indigo-500 focus:bg-violet-300/50 cursor-pointer placeholder:!text-gray-500  group-hover:placeholder:!text-indigo-500
        ${error ? `!border-rose-500 text-rose-500` : ``} ${
          isDisabled && "border-none !bg-gray-100"
        }`}
        disabled={isDisabled}
        maxDate={maxDate}
      >
        <div className="flex items-center justify-between w-full">
          <div>
            <button
              type="button"
              className="inline-flex items-center group text-violet-800 hover:text-white bg-gray-100 
                hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 fill-violet-500 hover:fill-white"
              onClick={() => {
                setStartDate("");
                calRef.current.setOpen(false);
                let event = { target: { name: name, value: "" } };
                handleOnChange(event);
              }}
            >
              <IKTSVG
                svgClassName="h-3 w-3 min-w-[0.75] mr-2"
                path={RefreshSVG}
              />
              <span>Reset</span>
            </button>
          </div>
          <div className="flex">
            <button
              onClick={() => {
                calRef.current.setOpen(false);
              }}
              type="button"
              className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                calRef.current.setOpen(false);
                if (isDateRange) {
                  setEndDate(endTempDate);
                } else {
                  setStartDate(startTempDate);
                  let event = { target: { name: name, value: startTempDate } };
                  handleOnChange(event);
                }
              }}
              className="text-white bg-violet-500 hover:bg-indigo-500 transition font-semibold rounded text-sm13 px-2.5 py-0.5"
            >
              Confirm
            </button>
          </div>
        </div>
      </DatePicker>
    </div>
  );
};

export default DatePickerField;
