import _, { find, isArray } from "lodash";
import moment from "moment";
import { memo, useContext, useMemo } from "react";
import { Tooltip } from "react-tooltip";
import handCross from "../../../../assets/media/icons/other_icons/cross-hand-icon.svg";
import handIcon from "../../../../assets/media/icons/other_icons/hand-icon.svg";
import logIcon from "../../../../assets/media/icons/other_icons/logSvg.svg";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import {
  getBenefitsValue,
  getCategoryValue,
  getRestrictionValue,
  getSectionValue,
  getTicketType,
} from "../../../../helpers/AssetHelpers";
import {
  colorForMeter,
  crc32,
  decimalNumber,
  firstLetterCaptital,
  priceCalculation,
} from "../../../../helpers/Functions";
import {
  INVENTORY_VALIDATE_COLUMN,
  SYMBOLS,
  deliveryTypeOrders,
} from "../../../../helpers/const";
import { TicketError } from "../../../add_inventory/core/_functions";
import { InventoryContext } from "../../core/InventoryProvider";
import {
  getFieldErrorColumns,
  visiblityMeterForBelowListing,
} from "../../core/_functions";

const EventFieldViewTest = memo(
  ({
    eventRecord,
    setList,
    list,
    eIndex,
    rIndex,
    event,
    disabledAll,
    forRowDisabled,
    currentRecordId,
    setCurrentRecordId,
    isTableEditable,
    firstSelectedIndex,
    getColumnItems,
    Messages,
  }: // isGodAdmin,
  any) => {
    // new code added
    const globalLayout = useContext(LayoutContext);
    const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;
    const conversation_rate = globalLayout?.conversationRate;
    const priceFactor = Number(
      globalLayout?.allowedAccess?.price_suggestion_factor
    );
    const calculateRowClasses = () => {
      let rowDisabledClass = "";
      let forRowDisabledClass = "";

      if (
        (eventRecord?.selected === false ||
          eventRecord?.selected === undefined) &&
        isTableEditable.length > 0
      ) {
        rowDisabledClass = "row-disabled";
      } else if (
        (eventRecord?.selected === false ||
          eventRecord?.selected === undefined) &&
        isTableEditable?.length === 0
      ) {
        rowDisabledClass = "";
      } else if (
        eventRecord?.selected === true &&
        isTableEditable?.length === 0
      ) {
        rowDisabledClass = "";
      } else if (
        eventRecord?.selected === true &&
        isTableEditable?.length > 0
      ) {
        rowDisabledClass = "row-indigo-light row-indigo-light -selected";
        forRowDisabledClass = "pointer-events-none !border-gray-300";
      }

      return { rowDisabledClass, forRowDisabledClass };
    };

    const { rowDisabledClass, forRowDisabledClass } = useMemo(
      calculateRowClasses,
      [eventRecord?.selected, isTableEditable?.length]
    );

    const isEventRecordHoldTickets =
      eventRecord?.hold_tickets &&
      Object.keys(eventRecord?.hold_tickets)?.length > 0;

    const isEventRecordError =
      eventRecord?.fieldErrors &&
      Object.keys(eventRecord?.fieldErrors)?.length > 0;
    const isEventRecordBEError =
      eventRecord?.errors && Object.keys(eventRecord?.errors)?.length > 0;
    // new code ended

    // const layout = useContext(InventoryContext);
    // const ticketTypeValue = { name: "abc" };
    // const categoryValue = { name: "abc" };
    // const sectionValue = { name: "abc" };
    // const benefitsValue = [];
    // const restrictionValue = [];

    const layout = useContext(InventoryContext);
    const ticketTypeValue =
      _.size(eventRecord) > 0 && getTicketType(eventRecord, layout);

    const categoryValue =
      _.size(eventRecord) > 0 &&
      _.size(event) > 0 &&
      getCategoryValue(eventRecord, event);
    const sectionValue =
      _.size(eventRecord) > 0 &&
      _.size(event) > 0 &&
      getSectionValue(eventRecord, event);
    const benefitsValue =
      _.size(eventRecord) > 0 && getBenefitsValue(eventRecord, layout);
    const restrictionValue =
      _.size(eventRecord) > 0 && getRestrictionValue(eventRecord, layout);

    const faceValue =
      typeof eventRecord?.face_value === "object"
        ? eventRecord?.face_value?.value
        : decimalNumber(eventRecord?.face_value);
    const proccedValue =
      typeof eventRecord?.sell_price === "object"
        ? eventRecord?.sell_price?.value
        : decimalNumber(eventRecord?.sell_price);

    const isNonPaperTicket = useMemo(() => {
      const ticketType = eventRecord?.ticket_type;

      if (typeof ticketType === "object" && ticketType?.id !== "paper") {
        return true;
      }

      if (typeof ticketType === "string" && ticketType !== "paper") {
        return true;
      }

      return false;
    }, [eventRecord?.ticket_type]);

    let seletedDeliveryTypes: any = [];
    let deliveryTypeExist: any = 0;
    let paperTypeExist: any = false;

    event?.eventRecords?.map((eventRecord: any) => {
      (eventRecord?.ticket_type?.id === "paper" ||
        eventRecord?.ticket_type == "paper") &&
        (paperTypeExist = true);
      seletedDeliveryTypes = [
        ...seletedDeliveryTypes,
        ...eventRecord?.delivery_options?.map(
          (dT: any) => _.find(layout.deliveryType, { id: dT.id })?.type
        ),
      ];
    });

    seletedDeliveryTypes = _.uniq(seletedDeliveryTypes);
    deliveryTypeExist = seletedDeliveryTypes?.length;

    let totalHold = 0;
    eventRecord?.hold_tickets &&
      Object.keys(eventRecord?.hold_tickets).length > 0 &&
      Object.values(eventRecord?.hold_tickets)?.map((holdData: any) => {
        totalHold = totalHold + holdData?.hold_quantity;
      });

    const rowClasses = [
      "view_row",
      "first_row",
      "flex",
      "columns-1",
      "h-[2.5625rem]",
      "border-b",
      "whitespace-nowrap",
      "column-view",
      "items-center",

      eventRecord?.bulkUpdate ? "row-disabled !bg-gray-300/40" : "",
      layout?.isBulkActionLoader ? "row-disabled !bg-gray-300/40" : "",
      eventRecord?.deleteLoader ? "fade-effect" : "",
      eventRecord?.bulkLoader ? "row-disabled" : "",
      paperTypeExist ? "delivery-type-exist" : "",
      layout.tableAlert?.confirm ? "pointer-events-none" : "",
      eventRecord?.id === firstSelectedIndex?.id && eventRecord?.selected
        ? "first"
        : "",
      event.value === "" ? "row-disabled" : "",
      eventRecord?.processing ? "row-processing" : "",
      rowDisabledClass,
      // eventRecord?.newRow ? "row-indigo-light ease-out duration-500" : "",
      isEventRecordHoldTickets ? "" : "", // Add your class here
      isEventRecordError || eventRecord?.status === "failed" ? "" : "", // Add your class here
      eventRecord?.status === "partPublished" ? "" : "", // Add your class here
      layout.isDeleteActive ? "pointer-events-none" : "",
      layout.isEditPopUpActive ? "pointer-events-none" : "",
      eventRecord?.selected && layout.confirmRowDelete ? "fade" : "",
      eventRecord?.deleteLoader ? "row-disabled" : "",
      eventRecord?.loader ||
      (eventRecord?.loader &&
        (eventRecord?.bulkLoader || layout?.updateAllLoader))
        ? "row-disabled"
        : "",
      layout.selectAllLoader
        ? "!row-disabled !pointer-events-none !bg-gray-300/40"
        : "",
      isGodAdmin && eventRecord?.isLock === true ? "bg-gray-300/40" : "",
    ];

    let pngImagePath = "/media/png/";

    const isPaper =
      eventRecord?.ticket_type === "paper" ||
      eventRecord?.ticket_type?.id === "paper";

    let splitCondition: string = "";
    if (
      (typeof eventRecord?.split_type === "string" &&
        eventRecord?.split_type !== "MULTIPLES") ||
      (typeof eventRecord?.split_type === "object" &&
        eventRecord?.split_type?.id !== "MULTIPLES")
    ) {
      splitCondition = "text-gray-400";
    } else {
      splitCondition = "";
    }

    const isALLTicketUploadedWithPublish =
      (Number(eventRecord?.listing_ticket_files.length) > 0 ||
        Number(eventRecord?.mobile_links?.length) > 0) &&
      Number(eventRecord?.quantity_available) > 0 &&
      Number(eventRecord?.quantity_available) ===
        (Number(eventRecord?.listing_ticket_files?.length) ||
          Number(eventRecord?.mobile_links?.length)) &&
      eventRecord?.status === "yes";

    // let fieldErrors = eventRecord?.fieldErrors
    //   ? Object.keys(eventRecord?.fieldErrors)
    //   : [];
    let fieldErrors: any = [];

    let splitTypeValue: any =
      typeof eventRecord?.split_type === "object"
        ? eventRecord?.split_type
        : Array.isArray(layout.splitType) &&
          layout.splitType.find(
            (split: any) => split.id === eventRecord?.split_type
          );

    const isPublishedWithGod =
      (isALLTicketUploadedWithPublish && !isGodAdmin) ||
      (isGodAdmin &&
        isALLTicketUploadedWithPublish &&
        eventRecord?.isLock === false);

    function categoryExist(event: any, record: any, type: any) {
      const categoryValue = record?.category?.id
        ? record?.category
        : event?.categories &&
          event?.categories.find(
            (category: any) => Number(category.id) === Number(record?.category)
          );

      const sectionValue =
        record?.section && record?.section?.id
          ? record?.section
          : record?.section &&
            event?.categories &&
            Array.isArray(event?.categories) &&
            event?.categories
              .find(
                (category: any) =>
                  Number(category.id) === Number(record?.category)
              )
              ?.sections?.find(
                (item: any) => Number(item?.id) === Number(record?.section)
              );

      const priceField =
        typeof record?.sell_price === "object"
          ? eventRecord?.sell_price?.value
          : record?.sell_price;

      const hasProceedValue = priceField !== "0.00" && priceField !== "";
      const hasNoCategory = _.size(categoryValue) === 0;
      const hasNoSection = _.size(sectionValue) === 0;

      if (type === "withoutcategory") {
        return _.size(record?.markinsight) > 0
          ? hasProceedValue && (hasNoCategory || hasNoSection)
          : false;
      }
      if (type === "withcategory") {
        return _.size(record?.markinsight) > 0
          ? hasProceedValue &&
              _.size(categoryValue) > 0 &&
              _.size(sectionValue) > 0
          : false;
      }
    }

    return (
      <>
        <div
          className={`view_row cloneView ${
            isEventRecordError ||
            eventRecord?.status === "failed" ||
            isEventRecordBEError
              ? "row-error-new group-hover:bg-rose-550"
              : ""
          } ${
            isEventRecordHoldTickets
              ? "group-hover:bg-sky-blue-300 row-hold-new"
              : ""
          } ${eventRecord?.processing ? "row-processing-new" : ""} ${
            isPublishedWithGod && "bg-white"
          }`}
          // onClick={() => {
          //   setCurrentRecordId({
          //     recordIndex: rIndex,
          //     eventIndex: eIndex,
          //     isActive: true,
          //   });
          // }}
          onMouseEnter={() => {
            // if (
            //   !(layout.isTableEditable?.length > 0 && eventRecord?.selected) ||
            //   !eventRecord?.selected
            // ) {
            //   setCurrentRecordId({ recordIndex: rIndex, eventIndex: eIndex });
            // }
            if (layout.isTableEditable?.length === 0) {
              setCurrentRecordId({ recordIndex: rIndex, eventIndex: eIndex });
            }
          }}
          onMouseLeave={(e: any) => {
            if (layout.isTableEditable?.length > 0) {
              setCurrentRecordId(undefined);
            }
          }}
        >
          <div className={`${rowClasses.join(" ")}`}>
            {isGodAdmin && (
              <div
                className={`lock-img-view td_block checkbox_td td_block zero-col ${
                  isPublishedWithGod && "bg-white"
                }`}
                id="checkbox_shad_lock"
              >
                <div className="lock-icon relative w-full h-full border-r  ">
                  <label
                    className={`cursor-pointer w-full h-full flex items-center justify-center ${
                      eventRecord?.isLock === true ? " lock" : "unlock"
                    }`}
                  >
                    <img
                      src={
                        eventRecord?.isLock === true
                          ? `${pngImagePath}lock.png`
                          : `${pngImagePath}unlock.png`
                      }
                      className="w-[.8125rem] h-[.9375rem]"
                    />
                  </label>
                </div>
              </div>
            )}
            <div
              className={`td_block checkbox_td first-col bg-inherit ${
                isGodAdmin ? "left-10" : "left-0"
              }
              ${isPublishedWithGod && "bg-white"}
              `}
            >
              <label
                className={`bg-inherit select-none w-full h-full top-0 left-0 absolute cursor-pointer p-1 border-r group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]
                 ${forRowDisabled} ${disabledAll && "pointer-events-none"} ${
                  eventRecord?.selected ? "bg-indigo-500 bg-opacity-[7%]" : ""
                }  ${
                  ((eventRecord?.errors &&
                    Object.keys(eventRecord?.errors ? eventRecord?.errors : [])
                      .length > 0) ||
                    eventRecord?.status === "failed") &&
                  "row-error-new bg-opacity-[5%] !group-hover:bg-rose-550 group-hover:bg-opacity-[5%]"
                } ${
                  (isEventRecordError ||
                    eventRecord?.status === "failed" ||
                    isEventRecordBEError) &&
                  "row-error-new !group-hover:bg-rose-550"
                }`}
                htmlFor={`checkbox-all-${eIndex}${rIndex}`}
              ></label>
              <input
                name="selected"
                id={`checkbox-all-${eIndex}${rIndex}`}
                type="checkbox"
                checked={eventRecord?.selected ? eventRecord?.selected : false}
                className={`${forRowDisabledClass} ${
                  disabledAll && "pointer-events-none "
                }`}
              />
            </div>
            {getColumnItems?.map((columnItem: any) => {
              switch (columnItem.id) {
                case 0:
                  return (
                    <div className=" disable second-col">
                      {crc32(eventRecord?.id)}
                    </div>
                  );
                case 1:
                  return (
                    <div className="p-1.5 min-w-[7.625rem] max-w-[7.625rem] ellips-text ">
                      {eventRecord?.team_name &&
                        firstLetterCaptital(eventRecord?.team_name)}
                    </div>
                  );
                case 2:
                  return (
                    <div className="p-1.5 min-w-[7.625rem] max-w-[7.625rem] ellips-text ">
                      {eventRecord?.user_name &&
                        firstLetterCaptital(eventRecord?.user_name)}
                    </div>
                  );
                case 3:
                  return (
                    <>
                      <div
                        className={` third-col ${
                          layout.tableDataEditChanging?.[eIndex]?.includes(
                            "ticket_type"
                          ) &&
                          eventRecord?.selected &&
                          "edited"
                        }   `}
                      >
                        <div className="DropdownInnerView">
                          {ticketTypeValue?.name}
                        </div>
                      </div>
                      {paperTypeExist && (
                        <div
                          className={`delivery-type disable four-col ${
                            !isPaper ? "text-gray-400" : "ena"
                          } ${
                            layout.tableDataEditChanging?.[eIndex]?.includes(
                              "delivery_options"
                            ) && "edited"
                          }  `}
                        >
                          <div className="DropdownInnerView">
                            {eventRecord?.delivery_options?.length > 0
                              ? ` ${eventRecord?.delivery_options?.length} selected `
                              : " Delivery type"}
                          </div>
                        </div>
                      )}

                      {paperTypeExist && seletedDeliveryTypes?.length > 0 && (
                        <>
                          {paperTypeExist &&
                            isArray(seletedDeliveryTypes) &&
                            seletedDeliveryTypes.length > 0 &&
                            _.orderBy(
                              seletedDeliveryTypes,
                              deliveryTypeOrders,
                              ["asc"]
                            ).map((selected: any) => {
                              let typeTemp = _.find(layout.deliveryType, {
                                type: selected,
                              });
                              let delivery = find(
                                eventRecord?.delivery_options,
                                {
                                  id: typeTemp?.id,
                                }
                              );
                              return delivery ? (
                                <div
                                  className={`p-1.5 min-w-[7.625rem] max-w-[7.625rem] five-col  ${
                                    layout.tableDataEditChanging?.[
                                      eIndex
                                    ]?.includes(delivery?.id) &&
                                    eventRecord?.selected &&
                                    "edited"
                                  } `}
                                >
                                  <div className="InputInnerView">
                                    {eventRecord?.[delivery?.id] != undefined
                                      ? eventRecord?.[delivery?.id]
                                      : delivery?.price
                                      ? Number(delivery?.price)
                                      : selected}
                                  </div>
                                </div>
                              ) : (
                                <div className="p-1.5 min-w-[7.625rem] max-w-[7.625rem] disable five-col">
                                  {selected}
                                </div>
                              );
                            })}
                        </>
                      )}
                    </>
                  );

                case 5:
                  return (
                    <>
                      <div
                        className={` eight-col ${
                          layout.tableDataEditChanging?.[eIndex]?.includes(
                            "quantity_available"
                          ) &&
                          eventRecord?.selected &&
                          "edited"
                        }`}
                      >
                        <div className="InputInnerView">
                          {eventRecord?.quantity_available}
                        </div>
                      </div>
                      <div className="nine-col">
                        <div className="InputInnerView">
                          {eventRecord?.quantity_sold}
                        </div>
                      </div>
                    </>
                  );
                case 6:
                  return (
                    <div
                      className={`ellips-text ten-col inline ${
                        layout.tableDataEditChanging?.[eIndex]?.includes(
                          "split_type"
                        ) &&
                        eventRecord?.selected &&
                        "edited"
                      } `}
                    >
                      <div className="DropdownInnerView">
                        {/* {eventRecord?.split_type?.name
                        ? eventRecord?.split_type?.name
                        : eventRecord?.split_type === "ANY"
                        ? "No prefer..."
                        : eventRecord?.split_type} */}
                        {splitTypeValue?.name === "No Preference"
                          ? "No preference"
                          : splitTypeValue?.name}
                      </div>
                    </div>
                  );
                case 7:
                  return (
                    <div
                      className={`eleven-col ${splitCondition} ${
                        layout.tableDataEditChanging?.[eIndex]?.includes(
                          "split_quantity"
                        ) &&
                        eventRecord?.selected &&
                        "edited"
                      } `}
                    >
                      <div className="InputInnerView">
                        {eventRecord?.split_quantity}
                      </div>
                    </div>
                  );
                case 8:
                  return (
                    <div
                      className={`twelve-col  ${
                        layout.tableDataEditChanging?.[eIndex]?.includes(
                          "quantity_display"
                        ) &&
                        eventRecord?.selected &&
                        "edited"
                      } `}
                    >
                      <div className="InputInnerView">
                        {eventRecord?.quantity_display
                          ? eventRecord?.quantity_display
                          : "Max display ..."}
                      </div>
                    </div>
                  );

                case 9:
                  return (
                    <div
                      className={`thirteen-col ${
                        layout.tableDataEditChanging?.[eIndex]?.includes(
                          "category"
                        ) &&
                        eventRecord?.selected &&
                        "edited"
                      } `}
                    >
                      <div className="DropdownInnerView">
                        {categoryValue?.name ? categoryValue?.name : "Category"}
                      </div>
                    </div>
                  );

                case 10:
                  return (
                    <div
                      className={`fourteen-col  ${
                        layout.tableDataEditChanging?.[eIndex]?.includes(
                          "section"
                        ) &&
                        eventRecord?.selected &&
                        "edited"
                      } `}
                    >
                      <div className="DropdownInnerView">
                        {sectionValue?.name
                          ? sectionValue?.name
                          : "Section/block"}
                      </div>
                    </div>
                  );

                case 11:
                  return (
                    <div
                      className={`fifteen-col ${
                        layout.tableDataEditChanging?.[eIndex]?.includes(
                          "row"
                        ) &&
                        eventRecord?.selected &&
                        "edited"
                      } `}
                    >
                      <div className="InputInnerView">
                        {eventRecord?.row ? eventRecord?.row : "Row"}
                      </div>
                    </div>
                  );
                case 12:
                  return (
                    <div
                      className={`sixteen-col ${
                        layout.tableDataEditChanging?.[eIndex]?.includes(
                          "first_seat"
                        ) &&
                        eventRecord?.selected &&
                        "edited"
                      } `}
                    >
                      <div className="InputInnerView">
                        {eventRecord?.first_seat
                          ? eventRecord?.first_seat
                          : "First seat"}
                      </div>
                    </div>
                  );

                case 13:
                  return (
                    <div
                      className={`seventeen-col ${
                        layout.tableDataEditChanging?.[eIndex]?.includes(
                          "face_value"
                        ) &&
                        eventRecord?.selected &&
                        "edited"
                      } `}
                      id={`face_value-${event.id}-${rIndex}`}
                    >
                      <div
                        className="priceInnerView"
                        data-sybmol-attribute={
                          eventRecord?.face_value_currency
                            ? SYMBOLS[eventRecord?.face_value_currency]
                            : SYMBOLS[eventRecord?.sell_price?.symbol]
                        }
                      >
                        <span>{faceValue ? faceValue : "Face value"}</span>
                      </div>
                    </div>
                  );
                case 14:
                  return (
                    !layout.proceedPriceToggle && (
                      <div
                        className={`eighteen-col ${
                          layout.tableDataEditChanging?.[eIndex]?.includes(
                            "sell_price"
                          ) &&
                          eventRecord?.selected &&
                          "edited"
                        } `}
                      >
                        <div
                          className="priceInnerView"
                          data-sybmol-attribute={
                            eventRecord?.sell_price_currency
                              ? SYMBOLS[eventRecord?.sell_price_currency]
                              : SYMBOLS[eventRecord?.sell_price?.symbol]
                          }
                        >
                          <span>
                            {proccedValue ? proccedValue : "Proceed Price"}
                          </span>
                        </div>
                      </div>
                    )
                  );

                case 15:
                  return (
                    <div
                      className={`twentyone-col ${
                        benefitsValue?.length ? "en" : "text-gray-500"
                      } ${
                        layout.tableDataEditChanging?.[eIndex]?.includes(
                          "benefits"
                        ) &&
                        eventRecord?.selected &&
                        "edited"
                      }  `}
                    >
                      <div className="DropdownInnerView">
                        {benefitsValue?.length
                          ? benefitsValue?.length + " selected"
                          : "Benefits"}
                      </div>
                    </div>
                  );
                case 16:
                  return (
                    <div
                      className={`twentytwo-col ${
                        restrictionValue?.length ? "en" : "text-gray-500"
                      } ${
                        layout.tableDataEditChanging?.[eIndex]?.includes(
                          "restrictions"
                        ) &&
                        eventRecord?.selected &&
                        "edited"
                      } `}
                    >
                      <div className="DropdownInnerView">
                        {restrictionValue?.length
                          ? restrictionValue?.length + " selected"
                          : "Restrictions"}
                      </div>
                    </div>
                  );

                case 17:
                  return (
                    <div
                      className={`inhand ${
                        eventRecord?.ticketsInHands ? "hand" : "nohand"
                      } ${
                        layout.tableDataEditChanging?.[eIndex]?.includes(
                          "ticketsInHands"
                        ) &&
                        eventRecord?.selected &&
                        "edited-new"
                      } nineteen-col  ${
                        layout.tableDataEditChanging?.[eIndex]?.includes(
                          "ticketsInHands"
                        ) &&
                        eventRecord?.selected &&
                        "edited"
                      }`}
                    >
                      {/* {eventRecord?.ticketsInHands ? "yes" : "No"} */}
                    </div>
                  );
                case 18:
                  return (
                    <div
                      className={`twenty-col ${
                        eventRecord?.ticketsInHands && "text-gray-400"
                      } ${
                        layout.tableDataEditChanging?.[eIndex]?.includes(
                          "date_to_ship"
                        ) &&
                        eventRecord?.selected &&
                        "edited"
                      }  `}
                    >
                      <div className="InputInnerView">
                        {eventRecord?.date_to_ship
                          ? moment(eventRecord?.date_to_ship).format(
                              "DD/MM/YYYY"
                            )
                          : "-"}
                      </div>
                    </div>
                  );
              }
            })}

            <div
              className={`twentythree-col ${
                layout.proceedPriceToggle &&
                "fixed_price_proceed !min-w-[20.75rem]"
              }`}
            >
              {layout.isTableEditable?.length === 0 &&
              eventRecord?.isInputChange ? (
                <div
                  className={`accordion-body 
                    ${eventRecord?.loader && "list-loading"} 
                    ${layout?.isBulkActionLoader && "list-loading"} 
                    left-shad ${
                      layout.proceedPriceToggle && "!justify-between !p-0"
                    }
                `}
                  id="left-shad-list"
                >
                  {layout.proceedPriceToggle && (
                    <div
                      className={`eighteen-col flex items-center flex-1 !max-w-[8.1819rem] border-r !px-2.5 !py-0 h-full ${
                        layout.tableDataEditChanging?.[eIndex]?.includes(
                          "sell_price"
                        ) && "edited"
                      }`}
                      data-sybmol-attribute={
                        eventRecord?.sell_price_currency
                          ? SYMBOLS[eventRecord?.sell_price_currency]
                          : SYMBOLS[eventRecord?.sell_price?.symbol]
                      }
                    >
                      <div
                        className="priceInnerView"
                        data-sybmol-attribute={
                          eventRecord?.sell_price_currency
                            ? SYMBOLS[eventRecord?.sell_price_currency]
                            : SYMBOLS[eventRecord?.sell_price?.symbol]
                        }
                      >
                        <span>
                          {proccedValue ? proccedValue : "Proceed Price"}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="btn-wrap flex items-center flex-1 justify-center">
                    {/* CANCEL RECORD BUTTON */}
                    <button type="button" className="cancel-view">
                      <img
                        src={`${pngImagePath}cross.png`}
                        className="w-[.5966rem] h-[.5966rem]"
                      />
                    </button>

                    {/* UPDATE RECORD BUTTON */}
                    <button type="button" className="upload-view">
                      <img
                        src={`${pngImagePath}Check.png`}
                        className="w-[.7585rem] h-[.6452rem]"
                      />
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  className={`1232 st_icons left-shad ${
                    isPublishedWithGod && "bg-white"
                  } ${
                    isEventRecordHoldTickets &&
                    "row-hold-new group-hover:bg-sky-blue-300"
                  } ${
                    isEventRecordError ||
                    (isEventRecordBEError &&
                      "row-error-new group-hover:bg-rose-550")
                  } ${layout.proceedPriceToggle && "flex justify-between"}`}
                  id="left-shad-list"
                >
                  {layout.proceedPriceToggle && (
                    <div
                      className={`eighteen-col flex items-center flex-1 !max-w-[8.1819rem] border-r !px-2.5 !py-0 ${
                        layout.tableDataEditChanging?.[eIndex]?.includes(
                          "sell_price"
                        ) && "edited"
                      }`}
                    >
                      <div
                        className="priceInnerView"
                        data-sybmol-attribute={
                          eventRecord?.sell_price_currency
                            ? SYMBOLS[eventRecord?.sell_price_currency]
                            : SYMBOLS[eventRecord?.sell_price?.symbol]
                        }
                      >
                        <span>
                          {proccedValue ? proccedValue : "Proceed Price"}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className={`icon-wrap`}>
                    <button
                      className="group flex flex-auto items-center justify-center p-1 md:p-1 w-8 md:w-10 md:max-w-[2.5rem] h-[2.5rem] border-r"
                      type="button"
                    >
                      {eventRecord?.ticketsInHands ? (
                        <IKTSVG
                          path={handIcon}
                          svgClassName={` w-3 group-hover:fill-indigo-500 ${
                            Number(eventRecord?.quantity_available) > 0 &&
                            Number(eventRecord?.quantity_available) ===
                              (Number(
                                eventRecord?.listing_ticket_files?.length
                              ) || Number(eventRecord?.mobile_links?.length))
                              ? "fill-gray-400"
                              : "fill-green-600"
                          } `}
                        />
                      ) : (
                        <IKTSVG
                          path={handCross}
                          svgClassName="fill-violet-500 w-3 group-hover:fill-indigo-500"
                        />
                      )}
                    </button>
                    <button className={`upload-img-view`} type="button">
                      <img
                        // src={`${pngImagePath}upload.png`}
                        src={`${
                          eventRecord?.ticket_type?.id !== "mobile-qr" &&
                          eventRecord?.ticket_type?.id !== "pdf" &&
                          eventRecord?.ticket_type !== "eticket" &&
                          eventRecord?.ticket_type !== "mobile-link" &&
                          eventRecord?.ticket_type?.id !== "mobile-link"
                            ? `${pngImagePath}upload-disable.png`
                            : Number(eventRecord?.quantity_available) > 0 &&
                              Number(eventRecord?.quantity_available) ===
                                (Number(
                                  eventRecord?.listing_ticket_files?.length
                                ) || Number(eventRecord?.mobile_links?.length))
                            ? `${pngImagePath}upload-success.png`
                            : eventRecord?.fieldErrors?.[
                                "quantity_available"
                              ] === TicketError
                            ? `${pngImagePath}upload-error.png`
                            : `${pngImagePath}upload.png`
                        }`}
                        alt="upload"
                        className="w-[.8125rem] h-[.6875rem]"
                      />
                    </button>

                    <button
                      className={`flex flex-auto items-center justify-center p-1 md:p-1 w-8 md:w-10 md:max-w-[2.5rem] h-[2.5rem] border-r`}
                      type="button"
                      id="visibility_addInventory"
                    >
                      <div
                        id={`eTicket-upload-tooltip-visibility${eIndex}${rIndex}`}
                        className={` table-meter flex gap-[.0875rem] svg-wrap
                        
                        ${
                          categoryExist(event, eventRecord, "withcategory")
                            ? colorForMeter(
                                eventRecord?.avg_price
                                  ? priceCalculation(
                                      typeof eventRecord?.sell_price ===
                                        "object"
                                        ? eventRecord?.sell_price?.value
                                        : eventRecord?.sell_price,
                                      eventRecord?.avg_price
                                        ? Number(eventRecord?.avg_price)
                                        : null,
                                      priceFactor
                                    )
                                  : false
                              )
                            : ""
                        } 
                        
                        ${
                          layout.animationForVisibility?.eIndex === eIndex &&
                          layout.animationForVisibility?.rIndex === rIndex &&
                          layout.animationForVisibility?.flag
                            ? ` visibility-case${
                                eventRecord?.oldVisibility > 2
                                  ? eventRecord?.oldVisibility - 2
                                  : eventRecord?.oldVisibility
                              }-${visiblityMeterForBelowListing(
                                eventRecord?.oldVisibility,
                                eventRecord?.visibility
                              )}`
                            : `visibility-case${visiblityMeterForBelowListing(
                                eventRecord?.oldVisibility,
                                eventRecord?.visibility
                              )}`
                        }
                        `}
                      >
                        <span className="s_block w-[2px] h-3 bg-gray-300 rounded-l-[1px]"></span>
                        <span className="s_block w-[2px] h-3 bg-gray-300"></span>
                        <span className="s_block w-[2px] h-3 bg-gray-300"></span>
                        {/* <span className="s_block w-[2px] h-3 bg-gray-300"></span> */}
                        <span className="s_block w-[2px] h-3 bg-gray-300 rounded-r-[1px]"></span>
                      </div>
                    </button>

                    {/* PUBLISH & UNBLISH */}
                    <button
                      className={`status-view collapsed `}
                      type="button"
                      id={`status-${event?.id}-${eventRecord?.id}`}
                    >
                      {eventRecord?.status === "yes" ? (
                        <img
                          src={`${pngImagePath}HaxPublish.png`}
                          className="w-[1rem] h-[1.155rem]"
                        />
                      ) : (
                        <img
                          src={`${pngImagePath}unpublish.png`}
                          className="w-[1rem] h-[1.155rem]"
                        />
                      )}
                    </button>
                    <button
                      className={`status-view collapsed border-l`}
                      type="button"
                      id={`logs-${event?.id}-${eventRecord?.id}`}
                    >
                      <Tooltip
                        anchorId={`logs-${event?.id}-${eventRecord?.id}`}
                        content={`Log`}
                        place={`top`}
                        variant="light"
                        className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 `}
                      />
                      <IKTSVG
                        className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition"
                        path={logIcon}
                        svgClassName="w-4 h-4"
                      />
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* ERROR & HOLD TICKET*/}
          </div>

          {isEventRecordError ||
          isEventRecordHoldTickets ||
          isALLTicketUploadedWithPublish ||
          _.size(fieldErrors) > 0 ||
          isEventRecordBEError ||
          eventRecord?.processing ? (
            <div className={`second_row flex !p-l-[2.5rem] w-full bg-gray-100`}>
              <div
                className={`border-b ${
                  isGodAdmin ? "w-[5rem]" : "w-[2.5rem]"
                }  min-h-[1.9375rem] sticky left-0`}
              ></div>
              <div className="p-0 equal-td-height border-b flex items-start sticky left-[2.5rem]">
                <div
                  className={`error-box border-b border-l border-r ml-1.5 -mt-[1px] rounded-b mb-2 
                  ${
                    isEventRecordHoldTickets &&
                    "row-hold-new group-hover:bg-sky-blue-300"
                  }
                  ${
                    isEventRecordError ||
                    (isEventRecordBEError &&
                      "row-error-new group-hover:bg-rose-550")
                  }
                  ${
                    isGodAdmin &&
                    eventRecord?.isLock === true &&
                    "bg-gray-300/40"
                  } ${isPublishedWithGod && "bg-white"}
                `}
                >
                  {fieldErrors && _.size(fieldErrors) > 0 && (
                    <div className="px-2.5 pb-[.3125rem] pt-0 flex text-rose-500 restriction">
                      Error:
                      <div>
                        <div className="text-violet-200 text-xs inline-block font-semibold flex pl-1">
                          There are some errors in {fieldErrors?.join(",")}
                        </div>
                      </div>
                    </div>
                  )}
                  {isEventRecordBEError &&
                    eventRecord?.errors?.map((error: any) => {
                      // Filter out errors that are included in INVENTORY_VALIDATE_COLUMN
                      const filteredErrors = error?.errors?.filter(
                        (e: string) =>
                          ![
                            ...INVENTORY_VALIDATE_COLUMN,
                            ...layout.deliveryType?.map((item: any) => item.id),
                          ]?.includes(e)
                      );

                      // Render only if there are non-excluded errors
                      if (filteredErrors && filteredErrors.length > 0) {
                        return (
                          <div className="px-2.5 pb-[.3125rem] pt-0 flex text-rose-500">
                            Error&nbsp;
                            <div>
                              {error.exchange && (
                                <span className="text-rose-500 text-xs inline-block font-semibold">
                                  {error.exchange}: &nbsp;
                                </span>
                              )}
                              <p className="!text-gray-600 inline font-normal">
                                {filteredErrors.join(",")}
                              </p>
                            </div>
                          </div>
                        );
                      }

                      // If no errors to render, return null or an empty fragment
                      return null;
                    })}
                  {isEventRecordError &&
                    _.size(
                      getFieldErrorColumns(eventRecord, layout?.deliveryType)
                    ) > 0 && (
                      <div className="px-2.5 pb-[.3125rem] pt-0 flex text-rose-500">
                        Error:&nbsp;
                        <div>
                          <p className="text-gray-600 inline font-normal">
                            There are some errors in{" "}
                            {getFieldErrorColumns(
                              eventRecord,
                              layout?.deliveryType
                            )?.join(", ")}
                            {_.size(
                              getFieldErrorColumns(
                                eventRecord,
                                layout?.deliveryType
                              )
                            ) > 1
                              ? " fields"
                              : " field"}
                          </p>
                        </div>
                      </div>
                    )}
                  {isALLTicketUploadedWithPublish && (
                    <div className="px-2.5 pb-[.3125rem] pt-0 flex text-green-500 restriction">
                      Tickets uploaded:
                      <div>
                        {"   "}
                        <div className="text-gray-600 text-xs inline-block font-medium flex pl-1">
                          {"   "} Editing restricted{" "}
                          <div
                            className="flex group pl-1"
                            // data-tooltip-id="editing"
                          >
                            {/* <IKTSVG
                              className="cursor-pointer fill-gray-400 hover:fill-indigo-500 pl-1 inline-block align-middle "
                              svgClassName="w-[.875rem] h-[.875rem]"
                              path={Warning}
                            /> */}
                            <img
                              className="w-[.875rem] h-[.875rem] group-hover:hidden"
                              src={`${pngImagePath}info-gray.png`}
                            />
                            <img
                              className="w-[.875rem] h-[.875rem] hover-icon hidden group-hover:block"
                              src={`${pngImagePath}info-purple.png`}
                            />
                            {/* <TooltipPortal
                              id={`editing`}
                              content={Messages?.ticketRestricted}
                              className="text-center !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium  text-xs z-10 max-w-[19rem] cursor-default "
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {isEventRecordHoldTickets && (
                    <div className="px-2.5 pb-[.3125rem] pt-0">
                      <div className="">
                        <p className="text-sky-blue-500 text-xs flex flex-inline font-semibold">
                          {Messages?.ticketHoldLine1_new}
                          <span className="text-gray-600 font-normal flex flex-inline">
                            &nbsp;{totalHold} of{" "}
                            {totalHold + eventRecord?.quantity_available}{" "}
                            tickets on hold for 30 mins. Editing is restricted
                            <span
                              className="ml-2 mt-0.5"
                              id={`${eventRecord?.id}-OnHoldMessageIcon`}
                              data-tooltip-id={`${eventRecord?.id}-OnHoldMessageIcon`}
                            >
                              <img
                                src={`${pngImagePath}info-gray.png`}
                                alt="infor-circle"
                                className="w-[.875rem] h-[.875rem]"
                              />
                            </span>
                          </span>
                        </p>

                        {/* {isArray(eventRecord?.hold_tickets) &&
                          eventRecord?.hold_tickets.map(
                            (hold_ticket: any, hIndex: number) => {
                              return (
                                <Fragment
                                  key={
                                    "eventFieldIn" + eventRecord?.id + hIndex
                                  }
                                >
                                  <p className="text-gray-600 font-normal flex flex-inline">
                                    {hold_ticket?.exchange}:{" "}
                                    {hold_ticket?.hold_quantity}
                                  </p>
                                </Fragment>
                              );
                            }
                          )} */}
                      </div>
                    </div>
                  )}
                  {eventRecord?.processing ? (
                    <div className="p-1 h-[100%]">
                      <p className="text-gray-600 inline font-normal">
                        {Messages?.ticketProcessing}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
);

export default EventFieldViewTest;
